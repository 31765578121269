import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import { useQuery } from "../../hook/useQuery";

const PagePayment = (props) => {
  const [paid, setPaid] = useState(false);
  const [succes, setSuccess] = useState(false);
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    checkPaid();
  }, []);

  const checkPaid = () => {
    setSuccess(false);
    setMsg("");
    setLoading(true);

    axios
      .get(
        CONFIG.URL + `/user/payment/status?invoice_number=${query.get("inv")}`
      )
      .then(function (response) {


        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          setPaid(response.data?.data[0]?.is_paid === "0" ? false : true);
          setEmail(response.data?.data[0]?.liaison_email);
        } else {
          setPaid(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setMsg("Something went wrong, please try again or contact admin");
      });
  };

  const onSubmit = (data) => {
    setSuccess(false);
    setMsg("");
    setLoading(true);
    const payload = {
      email: query.get("email"),
      invoice_number: query.get("inv"),
      file: data?.file[0],
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/user/payment/confirm", form_data)
      .then(function (response) {
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          setSuccess(true);
        } else {
          setMsg(response?.data?.message);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setMsg("Something went wrong, please try again or contact admin");
      });
  };

  return (
    <>
      <div
        className="relative w-full md:w-[500px] mx-auto h-screen flex flex-col justify-between items-center "
        style={{
          backgroundImage: `url('${props?.setting?.background}')`,
        }}
      >
        {paid ? (
          <div className="overflow-x-hidden z-10 relative flex flex-grow w-full h-auto justify-center items-start px-5 pt-40">
            <div className="">
              <div className="flex flex-col space-y-2">
                <div className=" text-white text-center font-bold text-2xl uppercase">
                  Thank you for registering
                </div>
              </div>
              <div className="h-auto flex flex-col w-full px-0  w-full mt-10">
                <div className=" flex justify-center items-center p-5">
                  <div className="w-full h-auto bg-[#0d132a] border-2 border-white text-white rounded-lg p-4 flex flex-col justify-center items-center">
                    <div className="text-base font-bold text-center">
                      Your Payment Status Has Been Verified
                    </div>

                    <div className="text-sm font-bold text-center mt-4">
                      Please check email: {email}, for information
                      more detail
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="overflow-x-hidden z-10 relative flex flex-grow w-full h-auto justify-center items-start px-5 pt-40">
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              {succes ? (
                <div className="">
                  <div className="flex flex-col space-y-2">
                    <div className=" text-white text-center font-bold text-2xl uppercase">
                      upload successful
                    </div>
                  </div>
                  <div className="h-auto flex flex-col w-full px-0  w-full mt-10">
                    <div className=" flex justify-center items-center p-5">
                      <div className="w-full h-auto bg-[#0d132a] border-2 border-white text-white rounded-lg p-4 flex flex-col justify-center items-center">
                        <div className="text-base font-bold text-center">
                          Thank you for uploading proof of payment, admin will
                          check your payment 1x24 hours. Please Wait for our
                          next confirmation email.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">
                  <div className="flex flex-col space-y-2">
                    <div className=" text-white text-center font-bold text-2xl uppercase">
                      UPLOAD PAYMENT
                    </div>
                  </div>
                  <div className="h-auto flex flex-col w-full px-0  max-w-lg mt-10">
                    <div className="mb-1">
                      <div className="text-[#fbda08] mb-1">Bank Transfer</div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        {...register("file", { required: true })}
                        className="w-full mb-1 min-w-[160px] px-2 py-2 bg-[#030609] border-2 border-white rounded-lg text-white"
                      />
                      {errors.file && (
                        <span className="text-red-500 text-sm ">
                          This field is required
                        </span>
                      )}
                    </div>

                    {msg !== "" && (
                      <div className="bg-red-500 text-white  px-5 rounded-md mb-8 w-full mt-4">
                        <div className="text-white text-center my-3 font-bold">
                          {msg}.
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-full flex justify-center items-center space-x-5 my-5">
                    <button
                      type="sumbit"
                      className="min-w-[160px] px-10 py-2 bg-transparent border-2 border-[#fbda08] rounded-lg text-white font-bold"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default PagePayment;
