import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import CONFIG from '../../constanta/config';
import { useQuery } from '../../hook/useQuery';
import Cookies from 'js-cookie';

const FormStep1 = (props) => {
  const { memberCompaniesDropdown } = props;
  const [classification, setClassification] = useState(null);
  const [statusMember, setStatusMember] = useState(null);
  const [memberCompanies, setMemberCompanies] = useState(null);
  const [title, setTitle] = useState();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm();

  const classificationDropdown = [
    { value: 'Visitor', label: 'Visitor' },
    { value: 'Attendee', label: 'Attendee' }
  ];

  const [accesPv, setAccesPv] = useState([
    { value: 'Day 1 In Person', label: 'Day 1', active: false },
    { value: 'Day 2 In Person', label: 'Day 2', active: false }
  ]);

  const toggleDay = (val) => {
    let _accesPV = [...accesPv];

    let day = _accesPV.find((v) => v.value === val);

    day.active = !day.active;

    setAccesPv(_accesPV);
  };

  const checkAcces = () => {
    let selected = accesPv.find((v) => v.active);

    if (selected) return false;
    else return true;
  };

  const onSubmit = (data) => {
    if (checkAcces()) {
      return;
    }

    let _acces = accesPv
      .filter((v) => v.active)
      .map((v) => v.value)
      .join(',');

    let payload = {
      classification: data.classification_dropdown.value,
      fullname: data.title_name.value + ' ' + data.fullname,
      job_title: data.job_title,
      email: data.email,
      phone: data.phone,
      company_name: data.company_name,
      company_address: data.company_address,
      company_phone: data.company_telephone,
      liaison_name:
        data.title_contact !== undefined
          ? data.title_contact?.value + ' '
          : '' + data?.liaison_name ?? '',
      liaison_email: data.liaison_email ?? '',
      liaison_phone: data.liaison_contact_number ?? '',
      access_privilege: _acces,
      qr_code: data.qr_code,
      id_gate: query.get('id_gate')
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + '/user/add', form_data, {
          headers: {
            token: Cookies.get('token')
          }
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === 'SUCCESS') {
            props.next('success');
          } else {
            setMsgError(response?.data?.message);
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const titleDropdown = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Dr.', value: 'Dr.' },
    { label: 'Professor', value: 'Professor' }
  ];

  const validateEmailMatch = (value) =>
    value === watch(`email`) || 'Emails do not match';

  const handleEmailValidation = (email) => {
    if (email === '') {
      return true;
    }

    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  const validateEmailMatch2 = (value) =>
    value === watch(`liaison_email`) || 'Emails do not match';

  const handleEmailValidation2 = (liaison_email) => {
    if (liaison_email === '') {
      return true;
    }

    const isValid = isValidEmail(liaison_email);

    const validityChanged =
      (errors.liaison_email && isValid) || (!errors.liaison_email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  return (
    <form
      autoComplete="off"
      role="presentation"
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-2 px-5 py-5 bg-white rounded-md w-full">
      <div className="flex flex-col justify-center items-center">
        <img
          src="https://eventqiu.com/assets/hsse_logo.png"
          className="h-24 object-contain mb-4"
          alt=""
        />
        <div className="text-center font-bold mb-3">
          Welcome to the 28th Annual HSSE Conference and Exhibition
          Registration.
        </div>
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">
          I am registering as a(n):
        </label>
        <Controller
          control={control}
          name="classification_dropdown"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref, name } }) => (
            <ReactSelect
              menuPlacement="auto"
              isClearable
              dropdown
              className={'react-select'}
              classNamePrefix={'react-select'}
              placeholder={'Classification Dropdown'}
              options={classificationDropdown}
              onChange={(val) => {
                setClassification(val);
                setValue('classification_dropdown', val);
              }}
              value={classification}
            />
          )}
        />
        {errors.classification_dropdown && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">Full Name:</label>
        <div className="flex flex-col space-y-2 w-full">
          <Controller
            control={control}
            name={`title_name`}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                menuPlacement="auto"
                isClearable
                dropdown
                className={'react-select whitespace-nowrap'}
                classNamePrefix={'react-select'}
                placeholder={'Title Dropdown'}
                options={titleDropdown}
                onChange={(val) => {
                  setValue(`title_name`, val);
                }}
                //value={title}
              />
            )}
          />

          {errors[`title_name`] && (
            <span className="text-red-500 text-sm">This field is required</span>
          )}

          <input
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 py-2 rounded-sm w-full h-[38px]"
            {...register(`fullname`, {
              required: true
            })}
          />
        </div>
        {errors[`fullname`] && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Job Title:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`job_title`, {
            required: true
          })}
        />
        {errors[`job_title`] && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Email Address:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`email`, {
            required: true,
            validate: handleEmailValidation
          })}
        />
        {errors[`email`] && (
          <>
            {errors[`email`]?.type === 'validate' ? (
              <span className="text-red-500 text-sm">Email not Valid</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Confirm Email Address:
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`confirm_email`, {
            required: true,
            validate: validateEmailMatch
          })}
        />
        {errors[`confirm_email`] && (
          <>
            {errors[`confirm_email`]?.type === 'validate' ? (
              <span className="text-red-500 text-sm">Emails do not match</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Contact Number:</label>
        <input
          type="text"
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`phone`, {
            required: true
          })}
        />
        <label className="w-full  text-gray-500 text-sm">
          *e.g. (XXX) XXX-XXXX
        </label>
        {errors[`phone`] && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div>
        <hr className="my-10" />
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Company Name:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register('company_name', {
            required: true
          })}
        />
        {errors.company_name && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Company Address:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register('company_address', {
            required: true
          })}
        />
        {errors.company_address && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Company Telephone:
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register('company_telephone', {
            required: true
          })}
        />
        <label className="w-full  text-gray-500 text-sm">
          *e.g. (XXX) XXX-XXXX
        </label>
        {errors.company_telephone && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div>
        <hr className="my-10" />
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">
          Name of Contact for Invoice Handling:
        </label>
        <div className="flex flex-col space-y-2 w-full">
          <Controller
            control={control}
            name="title_contact"
            rules={{ required: false }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                isClearable
                dropdown
                className={'react-select whitespace-nowrap'}
                classNamePrefix={'react-select'}
                placeholder={'Title Dropdown'}
                options={titleDropdown}
                onChange={(val) => {
                  setValue('title_contact', val);
                }}
                //value={title}
              />
            )}
          />

          <input
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 py-2 rounded-sm w-full h-[38px]"
            {...register('liaison_name', {
              required: false
            })}
          />
        </div>
        {(errors.title_contact || errors.liaison_name) && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Contact Phone:</label>
        <input
          type="text"
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register('liaison_contact_number', {
            required: false
          })}
        />
        <label className="w-full  text-gray-500 text-sm">
          *e.g. (XXX) XXX-XXXX
        </label>
        {errors.liaison_contact_number && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Contact Email Address:
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register('liaison_email', {
            required: false,
            validate: handleEmailValidation2
          })}
        />
        {errors.liaison_email && (
          <>
            {errors.liaison_email?.type === 'validate' ? (
              <span className="text-red-500 text-sm">Email not Valid</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Confirm Contact Email Address
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register('confirm_liaison_email', {
            required: false,
            validate: validateEmailMatch2
          })}
        />
        {errors.confirm_liaison_email && (
          <>
            {errors.confirm_liaison_email?.type === 'validate' ? (
              <span className="text-red-500 text-sm">Emails do not match</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div>
        <hr className="my-10" />
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Access:</label>
        <div className="space-x-5 flex">
          {accesPv.map((item, key) => {
            return (
              <button
                key={key}
                type="button"
                onClick={() => {
                  toggleDay(item.value);
                }}
                className={`${
                  item.active ? 'bg-blue-500' : 'bg-gray-500'
                } w-full px-10 py-2 rounded-lg text-white font-bold   text-center`}>
                {item.label}
              </button>
            );
          })}
        </div>
        {checkAcces() && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Scan QR Code:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register('qr_code', {
            required: true
          })}
        />
        {errors.qr_code && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div>
        {msgError !== '' && (
          <span className="text-red-500 text-sm my-3">{msgError}</span>
        )}
      </div>

      <div>
        <div className="flex space-x-3 justify-between mt-10 w-full">
          <div className="flex justify-center items-center w-max">
            <a
              href={`./${'checkin'}?id_gate=${query.get(
                'id_gate'
              )}&gate=${query.get('gate')}`}
              className={`md:min-w-[160px] whitespace-nowrap px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}>
              CANCEL
            </a>
          </div>
          <button
            type="submit"
            className={`w-full px-10 py-2 rounded-lg text-white font-bold bg-green-500  text-center`}>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormStep1;
