import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import helper from "../../utils/helper";
import axios from "axios";
import CONFIG from "../../constanta/config";

const companySectorDropdown = [
  { label: "Energy", value: "Energy" },
  { label: "Government (Ministry)", value: "Government (Ministry)" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Technology/IC", value: "Technology/IC" },
  { label: "Other", value: "Other" },
];

const titleDropdown = [
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Dr.", value: "Dr." },
  { label: "Professor", value: "Professor" },
];

const FormStep2 = (props) => {
  const [companySector, setCompanySector] = useState();
  const [title, setTitle] = useState();
  const [dataCategory, setDataCategory] = useState();
  const { listCategory } = props;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.postData.company_name !== "") {
      setValue("company_name", props.postData.company_name);
    }
    if (props.postData.company_address !== "") {
      setValue("company_address", props.postData.company_address);
    }
    if (props.postData.company_phone !== "") {
      setValue("company_telephone", props.postData.company_phone);
    }
    if (props.postData.liaison_name !== "") {
      setValue("liaison_name", props.postData.liaison_name);
    }
    if (props.postData.liaison_designation !== "") {
      setValue("liaison_designation", props.postData.liaison_designation);
    }
    if (props.postData.liaison_phone !== "") {
      setValue("liaison_contact_number", props.postData.liaison_phone);
    }
    if (props.postData.liaison_email !== "") {
      setValue("liaison_email", props.postData.liaison_email);
      setValue("confirm_liaison_email", props.postData.liaison_email);
    }

    if (props.postData.pin !== "") {
      setValue("registration_pin", props.postData.pin);
    }

    //dropdown
    if (props.postData.company_sector !== "") {
      handleSetCompanySector();
    }

    if (props.postData.liaison_title !== "") {
      handleSetTitle();
    }

    if (props.postData.category !== "") {
      handleSetDataCategory();
    }
  }, [props.postData]);

  const handleSetCompanySector = () => {
    let val = companySectorDropdown.find(
      (item) => item.value === props.postData.company_sector
    );

    setCompanySector(val);
    setValue("company_sector_dropdown", val);
  };

  const handleSetTitle = () => {
    let val = titleDropdown.find(
      (item) => item.value === props.postData.liaison_title
    );
    setTitle(val);
    setValue("title_dropdown", val);
  };

  const handleSetDataCategory = () => {
    let val = listCategory?.data.find(
      (item) => item.value === props.postData.category
    );

    setDataCategory(val);
    setValue("data_category_dropdown", val);
  };

  const handleSetAwardPrice = () => {
    switch (props.postData.classification) {
      case "Attendee":
        return helper.checkMember(props.postData.status) ? 650 : 750;

      case "Exhibitor":
        return 1500;

        case "Awards Only":
          return 650;

      default:
        return 0;
    }
  };

  const onSubmit = (data) => {
    props.handleSetPostData({
      company_name: data.company_name,
      company_address: data.company_address,
      company_phone: data.company_telephone,
      company_sector: data.company_sector_dropdown.value,
      liaison_title: data.title_dropdown.value,
      liaison_name: data.liaison_name,
      liaison_designation: "",
      liaison_phone: data.liaison_contact_number,
      liaison_email: data.liaison_email,
      category: data.data_category_dropdown.value,
      grouping_type: getGroupingType(data.data_category_dropdown.value),
      access_privilege: getAccesspPrivilege(data.data_category_dropdown.value),
      included_seats: getSeat(data.data_category_dropdown.value),
      categoryPrice: getCategoryPrice(data.data_category_dropdown.value),
      awardsPrice: handleSetAwardPrice(),
      json_user:
        data.data_category_dropdown.value === props.postData.category
          ? props.postData.json_user
          : "",
      pin:
        data.data_category_dropdown.value === "AMCHAM TT HSE Award Judges" ||
        data.data_category_dropdown.value === "HSE Awards Applicants"
          ? data?.registration_pin ?? ""
          : props?.postData?.pin ?? "",
    });
    props.next();
  };

  const getSeat = (val) => {
    let category = listCategory?.data.find((item) => item.value === val);

    return category.includedSeats;
  };

  const getAccesspPrivilege = (val) => {
    let category = listCategory?.data.find((item) => item.value === val);

    return category.access_privilege;
  };

  const getGroupingType = (val) => {
    let category = listCategory?.data.find((item) => item.value === val);

    return category.group;
  };

  const getCategoryPrice = (val) => {
    let category = listCategory?.data.find((item) => item.value === val);

    return helper.checkMember(props.postData.status)
      ? category.memberPrice
      : category.nonMemberPrice;
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const validateEmailMatch = (value) =>
    value === watch("liaison_email") || "Emails do not match";

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  const renderPrice = (val) => {
    return helper.checkMember(props.postData.status)
      ? helper.formatCurrency(val?.memberPrice)
      : helper.formatCurrency(val?.nonMemberPrice);
  };

  const handlePinValidation = async (pin) => {
    const payload = { pin: pin };

    var form_data = new FormData();
    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      const response = await axios.post(CONFIG.URL + "/user/pin", form_data);
      if (response?.data?.status === "SUCCESS") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <form
      autoComplete="off"
      role="presentation"
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-2 px-5 py-5 bg-white rounded-md w-full"
    >
      <div className="flex flex-col justify-center items-center">
        <img
          src="https://eventqiu.com/assets/hsse_logo.png"
          className="h-24 object-contain mb-4"
          alt=""
        />
        <div className="text-center font-bold">
          Please enter your company information
        </div>
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Company Name:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("company_name", {
            required: true,
          })}
        />
        {errors.company_name && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Company Address:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("company_address", {
            required: true,
          })}
        />
        {errors.company_address && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Company Telephone: {props.postData.company_phone}
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("company_telephone", {
            required: true,
          })}
        />
         <label className="w-full  text-gray-500 text-sm">*e.g. (XXX) XXX-XXXX</label>
        {errors.company_telephone && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">
          Company Sector:
        </label>
        <Controller
          control={control}
          name="company_sector_dropdown"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref, name } }) => (
            <ReactSelect
              menuPlacement="auto"
              isClearable
              dropdown
              className={"react-select"}
              classNamePrefix={"react-select"}
              placeholder={"Sector Dropdown"}
              options={companySectorDropdown}
              onChange={(val) => {
                setCompanySector(val);
                setValue("company_sector_dropdown", val);
              }}
              value={companySector}
            />
          )}
        />
        {errors.company_sector_dropdown && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">
          Name of Contact for Invoice Handling:
        </label>
        <div className="flex flex-col space-y-2 w-full">
          <Controller
            control={control}
            name="title_dropdown"
            rules={{ required: true }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                isClearable
                dropdown
                className={"react-select whitespace-nowrap"}
                classNamePrefix={"react-select"}
                placeholder={"Title Dropdown"}
                options={titleDropdown}
                onChange={(val) => {
                  setTitle(val);
                  setValue("title_dropdown", val);
                }}
                value={title}
              />
            )}
          />

          <input
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 py-2 rounded-sm w-full h-[38px]"
            {...register("liaison_name", {
              required: true,
            })}
          />
        </div>
        {(errors.title_dropdown || errors.liaison_name) && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      {/* <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Liaison Designation:
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("liaison_designation", {
            required: true,
          })}
        />
        {errors.liaison_designation && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div> */}

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Contact Phone:</label>
        <input
          type="text"
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("liaison_contact_number", {
            required: true,
          })}
        />
        <label className="w-full  text-gray-500 text-sm">*e.g. (XXX) XXX-XXXX</label>
        {errors.liaison_contact_number && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Contact Email Address:
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("liaison_email", {
            required: true,
            validate: handleEmailValidation,
          })}
        />
        {errors.liaison_email && (
          <>
            {errors.liaison_email?.type === "validate" ? (
              <span className="text-red-500 text-sm">Email not Valid</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Confirm Contact Email Address
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("confirm_liaison_email", {
            required: true,
            validate: validateEmailMatch,
          })}
        />
        {errors.confirm_liaison_email && (
          <>
            {errors.confirm_liaison_email?.type === "validate" ? (
              <span className="text-red-500 text-sm">Emails do not match</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      {listCategory?.data.length > 0 && (
        <div className="flex flex-col">
          <label className="text-black w-full font-bold mb-1">
            {listCategory?.label}
          </label>
          <Controller
            control={control}
            name="data_category_dropdown"
            rules={{ required: true }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                menuPlacement="top"
                isClearable
                dropdown
                className={"react-select"}
                classNamePrefix={"react-select"}
                placeholder={listCategory?.label}
                options={listCategory?.data}
                onChange={(val) => {
                  setDataCategory(val);
                  setValue("data_category_dropdown", val);
                }}
                value={dataCategory}
                getOptionLabel={(option) => {
                  return `${option.label} - ${renderPrice(option)}`;
                }}
              />
            )}
          />
          {errors.data_category_dropdown && (
            <span className="text-red-500 text-sm">This field is required</span>
          )}
          {dataCategory !== null && dataCategory !== undefined && (
            <label className="text-black w-full font-bold mb-1">
              Price : {renderPrice(dataCategory)}
            </label>
          )}
        </div>
      )}

      {(dataCategory?.value === "AMCHAM TT HSE Award Judges" ||
        dataCategory?.value === "HSE Awards Applicants") && (
        <div className="flex flex-col">
          <label className="text-black w-full font-bold">
            Enter Your Registration PIN
          </label>
          <input
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 py-2 rounded-sm"
            {...register("registration_pin", {
              required: true,
              validate: handlePinValidation,
            })}
          />
          {errors.registration_pin && (
            <>
              {errors.registration_pin?.type === "validate" ? (
                <span className="text-red-500 text-sm">Pin not Valid</span>
              ) : (
                <span className="text-red-500 text-sm">
                  This field is required
                </span>
              )}
            </>
          )}
        </div>
      )}

      <div>
        <div className="flex space-x-3 justify-between mt-10 w-full">
          <button
            type="button"
            onClick={() => props.back()}
            className={`py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            BACK
          </button>
          <button
            type="submit"
            className={`py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormStep2;
