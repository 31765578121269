import { useEffect, useState } from 'react';
import QRScan from '../../components/qr_scan';
import { Controller, useForm } from 'react-hook-form';
import CONFIG from '../../constanta/config';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hook/useUser';
import ReactSelect from 'react-select';
import moment from 'moment';
import { useQuery } from '../../hook/useQuery';

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState('');
  const [showProduct, setShowProduct] = useState(false);
  const [listProduct, setListProduct] = useState([]);
  const [gate, setGate] = useState(null);
  const [msgError, setMsgError] = useState('');

  useEffect(() => {
    if (props?.leadcapture) {
      let products = props?.leadcapture?.product?.split(',').map((v) => {
        return {
          label: v,
          active: false
        };
      });

      setListProduct(products);
    }
  }, [props?.leadcapture]);

  useEffect(() => {
    let check = listProduct.find((v) => v.active);

    if (check) {
      setMsgError('');
    }
  }, [listProduct]);

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (checkAllow === "allowed-to-checkin") {
  //       setTimeout(() => {
  //         handleCheckin();
  //       }, 1000);
  //       return;
  //     } else {
  //       if (checkAllow !== "allowed-to-re-checkin") {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1500);
  //         return;
  //       }
  //     }
  //   }
  // }, [checkAllow]);

  const togleProduct = (idx) => {
    let arr = [...listProduct];
    arr[idx].active = !arr[idx].active;
    setListProduct(arr);
  };

  const onSubmit = () => {
    let check = listProduct.find((v) => v.active);

    if (!check) {
      setMsgError('Please choose product!');
    }

    if (loading) return;

    setLoading(true);

    handleSubmit();
  };

  const handleSubmit = () => {

    // eslint-disable-next-line array-callback-return

    let _product = listProduct.filter((v) => v.active).map((e,) => e.label);

    const payload = {
      id_lead: props?.leadcapture?.id_lead,
      id_user: props?.user?.id_user,
      product: _product.join(",")
    };

    debugger;

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + '/event/lead/capture', form_data, {
          headers: {
            token: CONFIG.TOKEN_LEAD
          }
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === 'SUCCESS') {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleReentry = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get('id_gate')
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + '/event/reentry', form_data, {
          headers: {
            token: CONFIG.TOKEN_LEAD
          }
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === 'SUCCESS') {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (gate) {
      if (gate?.checkin_time !== '0000-00-00 00:00:00') {
        setCheckAllow('already-checkin');
      } else {
        setCheckAllow('allowed-to-checkin');
      }
    } else {
      setCheckAllow('wrong-gate');
    }
  };

  const getCheckinTime = () => {
    if (Date.parse(gate?.reentry_time) > Date.parse(gate?.checkin_time)) {
      return gate?.reentry_time;
    } else {
      return gate?.checkin_time;
    }
  };

  const handleShowProduct = () => {
    if (showProduct) {
      setListProduct([]);
      setShowProduct(false);
    } else {
      setListProduct([
        {
          label: 'Product 1',
          active: true
        },
        {
          label: 'Product 2',
          active: false
        }
      ]);
      setShowProduct(true);
    }
  };

  return (
    <>
      <div className="px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold mb-2">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="">LEADS CAPTURE</div>
          <div className="text-2xl uppercase">
            {props?.leadcapture?.company_name}
          </div>
          <div className="text-lg">{props?.leadcapture?.company_email}</div>
          <a
            href={`https://hsse-api.eventqiu.com/media/${props?.leadcapture?.brochure_file}`}
            target="_blank"
            className="text-lg underline text-blue-600">
            Link Brochure
          </a>
        </div>

        <div className="bg-green-700 w-full text-center py-2 mt-3 text-white font-bold">
          PRODUCT
        </div>

        <div className="flex flex-col space-y-3 w-full p-3 border border-green-700 mb-3">
          {listProduct.map((v, i) => {
            return (
              <button
                key={i}
                type="button"
                onClick={() => togleProduct(i)}
                className={`${
                  v.active ? 'bg-green-500' : 'bg-gray-500'
                } px-10 py-2 rounded-lg text-white font-bold  text-center`}>
                {v.label}
              </button>
            );
          })}
        </div>

        <div className="bg-blue-700 w-full text-center py-2 mt-3 text-white font-bold">
          USER
        </div>

        <div className="flex flex-col space-y-1 w-full p-3 border border-blue-700 mb-3">
          <div className="text-center text-xl font-bold text-black uppercase mb-0">
            {props.user.fullname}
          </div>
          <div className="text-center text-lg font-bold text-black  mb-0">
            {props.user.classification}
          </div>
          <div className="text-center text-lg font-bold text-black  mb-0">
            {props.user.company_name}
          </div>
        </div>

        <br />

        {msgError !== '' && (
          <>
            <div className="bg-red-500 w-full p-2 flex justify-center items-center text-white font-semibold">
              {msgError}
            </div>
            <br />
          </>
        )}

        <div className="mx-auto flex justify-center items-center space-x-4 w-full">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}>
            CANCEL
          </button>

          <button
            type="button"
            onClick={() => onSubmit()}
            className={`px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}>
            {loading ? 'Loading...' : 'SUBMIT'}
          </button>
        </div>
      </div>
    </>
  );
};

export default FormRFIDResult;
