import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import CONFIG from "../../constanta/config";

const FormStep1 = (props) => {
  const { memberCompaniesDropdown } = props;
  const [classification, setClassification] = useState(null);
  const [statusMember, setStatusMember] = useState(null);
  const [memberCompanies, setMemberCompanies] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.postData.classification !== "") {
      handleSetClassification();
    }

    if (props.postData.pin !== "") {
      setValue("registration_pin", props.postData.pin);
    }

    if (props.postData.status !== "") {
      handleSetStatus();
    }

    if (props.postData.company_name !== "") {
      handleSetMemberCompany();
    }
  }, [props.postData]);

  const handleSetClassification = () => {
    let val = classificationDropdown.find(
      (item) => item.value === props.postData.classification
    );

    setClassification(val);
    setValue("classification_dropdown", val);
  };

  const handleSetStatus = () => {
    let val = membershipStatusDropdown.find(
      (item) => item.value === props.postData.status
    );
    setStatusMember(val);
    setValue("status_dropdown", val);
  };

  const handleSetMemberCompany = () => {
    let val = memberCompaniesDropdown.find(
      (item) => item.value === props.postData.company_name
    );
    setMemberCompanies(val);
    setValue("member_company", val);
  };

  const classificationDropdown = [
    { value: "Attendee", label: "Attendee" },
    { value: "Exhibitor", label: "Exhibitor" },
    { value: "Sponsor", label: "Sponsor" },
    { value: "Special Invite", label: "Special Invite" },
    // { value: "Awards Only", label: "Awards Only" },
  ];

  const membershipStatusDropdown = [
    { value: "Member Company or Ministry", label: "Member Company or Ministry" },
    { value: "Non-Member", label: "Non-Member" },

  ];

  const onSubmit = (data) => {
    props.handleSetPostData({
      classification: data.classification_dropdown.value,
      status: data.status_dropdown.value,
      pin: data.registration_pin ?? "",
      company_name: data.member_company?.name || "",
      company_address: data.member_company?.address || "",
      company_phone: data.member_company?.telephone || "",
    });

    props.next("step2");
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handlePinValidation = async (pin) => {
    const payload = { pin: pin };

    var form_data = new FormData();
    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      const response = await axios.post(CONFIG.URL + "/user/pin", form_data);
      if (response?.data?.status === "SUCCESS") {

        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <form
      autoComplete="off"
      role="presentation"
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-2 px-5 py-5 bg-white rounded-md w-full"
    >
      <div className="flex flex-col justify-center items-center">
        <img
          src="https://eventqiu.com/assets/hsse_logo.png"
          className="h-24 object-contain mb-4"
          alt=""
        />
        <div className="text-center font-bold mb-3">Welcome to the 28th Annual HSSE Conference and Exhibition Registration.</div>
        <div className="text-center font-bold">Register now to secure your spot at this premier event and be part of a community dedicated to</div>
        <div className="text-center font-bold">advancing HSSE standards and practices.</div>
        <div className="text-center font-bold mt-3">We look forward to welcoming you!</div>




      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">
          I am registering as a(n):
        </label>
        <Controller
          control={control}
          name="classification_dropdown"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref, name } }) => (
            <ReactSelect
              menuPlacement="auto"
              isClearable
              dropdown
              className={"react-select"}
              classNamePrefix={"react-select"}
              placeholder={"Classification Dropdown"}
              options={classificationDropdown}
              onChange={(val) => {
                if (
                  val === null ||
                  (val?.value !== "Sponsor" && val?.value !== "Special Invite")
                ) {
                  setValue("registration_pin", "");
                }

                setClassification(val);
                setValue("classification_dropdown", val);
              }}
              value={classification}
            />
          )}
        />
        {errors.classification_dropdown && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      {(classification?.value === "Sponsor" ||
        classification?.value === "Special Invite") && (
        <div className="flex flex-col">
          <label className="text-black w-full font-bold">
            Enter Your Registration PIN
          </label>
          <input
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 py-2 rounded-sm"
            {...register("registration_pin", {
              required:
                classification?.value === "Sponsor" ||
                classification?.value === "Special Invite"
                  ? true
                  : false,
              validate: handlePinValidation,
            })}
          />
          {errors.registration_pin && (
            <>
              {errors.registration_pin?.type === "validate" ? (
                <span className="text-red-500 text-sm">Pin not Valid</span>
              ) : (
                <span className="text-red-500 text-sm">
                  This field is required
                </span>
              )}
            </>
          )}
        </div>
      )}

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">
          Membership Status:
        </label>
        <Controller
          control={control}
          name="status_dropdown"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref, name } }) => (
            <ReactSelect
              menuPlacement="auto"
              isClearable
              dropdown
              className={"react-select"}
              classNamePrefix={"react-select"}
              placeholder={"Status Dropdown"}
              options={membershipStatusDropdown}
              onChange={(val) => {
                if (val === null || val?.value === "Non-Member") {
                  setMemberCompanies(null);
                  setValue("member_company", "");
                }

                setStatusMember(val);
                setValue("status_dropdown", val);
              }}
              value={statusMember}
            />
          )}
        />
        {errors.status_dropdown && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      {statusMember?.value === "Member Company or Ministry" && (
        <div className="flex flex-col">
          <label className="text-black w-full font-bold mb-1">
            Select Your Member Company or Ministry:
          </label>
          <Controller
            control={control}
            name="member_company"
            rules={{
              required: statusMember?.value === "Member Company or Ministry" ? true : false,
            }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                isClearable
                dropdown
                className={"react-select"}
                classNamePrefix={"react-select"}
                placeholder={"Member Company or Ministry"}
                options={memberCompaniesDropdown}
                onChange={(val) => {
                  setMemberCompanies(val);
                  setValue("member_company", val);
                }}
                value={memberCompanies}
              />
            )}
          />
          {errors.member_company && (
            <span className="text-red-500 text-sm">This field is required</span>
          )}
        </div>
      )}

      <div>
        <div className="flex space-x-3 justify-between mt-10 w-full">
          <button
            type="submit"
            className={`w-full px-10 py-2 rounded-lg text-white font-bold bg-green-500  text-center`}
          >
            Start Registration
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormStep1;
