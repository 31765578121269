const listSpecialInvitees = [
  {
    category: "VIP/Special Guest",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "VIP/Special Guest",
    label: "VIP/Special Guest",
    group: "Individual",
    access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person"],
  },
  {
    category: "AMCHAMTT Board Director",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "AMCHAMTT Board Director",
    label: "AMCHAMTT Board Director",
    group: "Individual",
    access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person", "Awards"],
  },
  {
    category: "Speaker/Panelist/Moderator",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Speaker/Panelist/Moderator",
    label: "Speaker/Panelist/Moderator",
    group: "Individual",
    access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person"],
  },
];

export default listSpecialInvitees;
