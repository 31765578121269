import { useEffect, useState } from 'react';
import QRScan from '../../components/qr_scan';
import { Controller, useForm } from 'react-hook-form';
import CONFIG from '../../constanta/config';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hook/useUser';
import ReactSelect from 'react-select';
import moment from 'moment';
import { useQuery } from '../../hook/useQuery';

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState('');
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find(
        (v) => v.id_gate === query.get('id_gate')
      );

      if (query.get('id_gate') === '1' || query.get('id_gate') === '2') {
        let tokenGate = props.user.event_gate?.find((v) => v.id_gate === '3');

        if (tokenGate) {
          setIsToken(true);
          props.setAksesToken(true);
        }
      }

      setGate(_gate);
    }
  }, [props?.user]);

  useEffect(() => {
    initAllowed();
  }, [gate]);

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (checkAllow === "allowed-to-checkin") {
  //       setTimeout(() => {
  //         handleCheckin();
  //       }, 1000);
  //       return;
  //     } else {
  //       if (checkAllow !== "allowed-to-re-checkin") {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1500);
  //         return;
  //       }
  //     }
  //   }
  // }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    if (checkAllow === 'allowed-to-checkin') {
      handleCheckin();
    } else {
      handleReentry();
    }
  };

  const handleCheckin = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get('id_gate')
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + '/event/checkin', form_data, {
          headers: {
            token: Cookies.get('token')
          }
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === 'SUCCESS') {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleReentry = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get('id_gate')
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + '/event/reentry', form_data, {
          headers: {
            token: Cookies.get('token')
          }
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === 'SUCCESS') {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    debugger;
    if (
      props.user.classification.toLowerCase() === 'visitor' &&
      (query.get('id_gate') === '7' || query.get('id_gate') === '8')
    ) {
      setCheckAllow('wrong-gate');
    }
    else if(  props.user.category.toLowerCase() === 'add: representative (with no meal)' &&
    (query.get('id_gate') === '7' || query.get('id_gate') === '8')){
      setCheckAllow('wrong-gate');
    }
    else {
      if (gate) {
        if (gate?.checkin_time !== '0000-00-00 00:00:00') {
          setCheckAllow('already-checkin');
        } else {
          setCheckAllow('allowed-to-checkin');
        }
      } else {
        setCheckAllow('wrong-gate');
      }
    }
  };

  const getCheckinTime = () => {
    if (Date.parse(gate?.reentry_time) > Date.parse(gate?.checkin_time)) {
      return gate?.reentry_time;
    } else {
      return gate?.checkin_time;
    }
  };

  const handleShowGuest = () => {
    if (showGuest) {
      setListGuest([]);
      setShowGuest(false);
    } else {
      setListGuest([
        {
          label: 'Guest 1',
          active: true
        },
        {
          label: 'Guest 2',
          active: false
        }
      ]);
      setShowGuest(true);
    }
  };

  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="text-xl uppercase">{query.get('gate')} Gate</div>
        </div>

        {checkAllow === 'allowed-to-checkin' && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO ENTER
          </div>
        )}

        {checkAllow === 'allowed-to-re-checkin' && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO ENTER
          </div>
        )}

        {checkAllow === 'already-checkin' && (
          <div className="uppercase bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            ALREADY Checked In : {getCheckinTime()}
          </div>
        )}

        {checkAllow === 'not-allowed-to-checkin' && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            WRONG GATE
          </div>
        )}

        {checkAllow === 'wrong-gate' && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            WRONG GATE
          </div>
        )}

        {/* <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
          ALREADY CHECKED IN
        </div> */}

        <div
          className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}>
          {props.user !== null ? (
            <>
              {props.user?.rfid !== '' && (
                <>
                  {/* <div className="mb-2 flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded">
                    {checkAllow === "allowed-to-re-checkin" && (
                      <img
                        src={CONFIG.URL_MEDIA + "/" + gate?.photo}
                        className="rounded w-full h-full  bject-contain"
                        alt=""
                      />
                    )}
                  </div> */}
                  <div className="text-center text-2xl font-bold text-black uppercase mb-2">
                    <span className="font-normal">Name:</span>{' '}
                    {props.user.fullname}
                  </div>

                  <div className="text-center text-xl font-bold text-black">
                    <span className="font-normal">Company:</span>{' '}
                    {props.user.company_name}
                  </div>
                  <div className="text-center text-xl font-bold text-black">
                    <span className="font-normal">Classification:</span>{' '}
                    {props.user.classification}
                  </div>
                  <div className="text-center text-xl font-bold text-black">
                    <span className="font-normal">Category:</span>{' '}
                    {props.user.category}
                  </div>
                  <div className="text-center text-xl font-bold text-black">
                    <span className="font-normal">Access:</span>{' '}
                    {props.user.access_privilege}
                  </div>
                </>
              )}
              {/* <div className="text-center text-3xl font-bold text-black uppercase mt-4">
                {props.user.event || ""}
              </div> */}
            </>
          ) : (
            <>
              {' '}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        {checkAllow === 'allowed-to-checkin' && (
          <div className="mx-auto flex justify-center items-center flex-col">
            {showGuest && (
              <div className="mx-auto flex justify-center items-center space-x-4 mb-5">
                {listGuest.map((v, i) => {
                  return (
                    <button
                      key={i}
                      type="button"
                      onClick={() => togleGuest(i)}
                      className={`${
                        v.active ? 'bg-blue-500' : 'bg-gray-500'
                      } px-10 py-2 rounded-lg text-white font-bold  text-center`}>
                      {v.label}
                    </button>
                  );
                })}
              </div>
            )}

            {/* <button
              type="button"
              onClick={() => handleShowGuest()}
              className={` ${
                showGuest ? 'bg-orange-500' : 'bg-blue-500'
              } mb-5 px-5 py-1 rounded-lg text-white font-bold  text-center`}>
              {showGuest ? 'Remove Guest' : 'Add Guest'}
            </button> */}
          </div>
        )}

        <>
          <div className="mx-auto flex justify-center items-center space-x-4 w-full">
            <button
              type="button"
              onClick={() => props.ok()}
              className={`px-2 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}>
              CANCEL
            </button>

            {checkAllow === 'already-checkin' &&
              isToken &&
              (query.get('id_gate') === '1' ||
                query.get('id_gate') === '2') && (
                <button
                  type="button"
                  onClick={() => props.onSuccess()}
                  className={`px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}>
                  TOKEN SCAN
                </button>
              )}

            {(checkAllow === 'allowed-to-re-checkin' ||
              checkAllow === 'allowed-to-checkin') && (
              <button
                type="button"
                onClick={() => onSubmit()}
                className={`px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}>
                {loading ? 'Loading...' : 'CONFIRM'}
              </button>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;
