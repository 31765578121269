import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import PageLogin from './pages/login';
import PageEvent from './pages/event';
import PageRegistration from './pages/registration';
import axios from 'axios';
import CONFIG from './constanta/config';
import PageCheckinOffline from './pages/checkin_offline';
import PageAddUser from './pages/add_user';
import PageCheckoutOffline from './pages/checkout';
import PageCheckinMobile from './pages/checkin_mobile';
import PagePayment from './pages/payment';
import PageLeadCapture from './pages/leadcapture';
import PageRegistrationEvent from './pages/registration-event';

function App() {
  const [setting, setSetting] = useState(null);

  useEffect(() => {
    // checkLocal();
    getListSetting();
  }, []);

  const getListSetting = () => {
    axios
      .get(CONFIG.URL + '/config')
      .then(function (response) {
        if (response?.data.status === 'SUCCESS') {
          setSetting(response?.data?.config);
        } else {
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      <div className="overflow-y-auto">
        <Routes>
          <Route path="/" element={<PageRegistration setting={setting} />} />
          <Route path="/register-event" element={<PageRegistrationEvent setting={setting} />} />
          <Route
            path="/registration"
            element={<PageRegistration setting={setting} />}
          />
          <Route path="/payment" element={<PagePayment setting={setting} />} />

          <Route path="/home" element={<PageEvent setting={setting} />} />
          <Route path="/login" element={<PageLogin setting={setting} />} />

          <Route
            path="/checkin"
            element={<PageCheckinMobile setting={setting} />}
          />

          <Route
            path="/leadcapture"
            element={<PageLeadCapture setting={setting} />}
          />

        </Routes>
      </div>
    </>
  );
}

export default App;
