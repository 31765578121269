import { useRef, useState } from "react";
import { QrReader } from "react-qr-reader";
// import QrReader from "modern-react-qr-reader";
// import { QrReader } from "@blackbox-vision/react-qr-reader";
// import { useTorchLight } from "@blackbox-vision/use-torch-light";

const QRScan = (props) => {
  const streamRef = useRef(null);

  const [error, setError] = useState(null);
  const [data, setData] = useState("No result");

  // const [on, toggle] = useTorchLight(streamRef.current, {
  //   debug: true,
  //   vibrate: 200
  // });

  const setRef = ({ stream }) => {
    streamRef.current = stream;
  };


  const handleScan = (data) => {
    if (data) {
      props.onSuccess(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen bg-black"></div>
      <div className="fixed py-20 z-50 bg-black  top-0 left-0 w-screen h-screen flex flex-col justify-between items-center">
        <div className="flex flex-col space-y-2">
        <div className=" text-white text-center font-bold text-2xl uppercase">
            {props.event}
          </div>
          <div className=" text-white text-center font-bold text-2xl uppercase">
            {props.gate}
          </div>
          <div className=" text-white text-center font-semibold">
            Please scan QR Code
          </div>
        </div>
        <div className=" w-full flex justify-center items-center space-x-4">
          {/* <button
            onClick={toggle}
            className="md:min-w-[160px] px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
          >
            {on ? "Disable Torch" : "Enable Torch"}
          </button> */}
          <button
            onClick={() => props.onClose()}
            className="md:min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
          >
            CLOSE
          </button>
        </div>
      </div>
      <div className="border-8 border-white fixed top-1/2 z-50 -translate-y-1/2 -translate-x-1/2 left-1/2 w-11/12 h-auto md:pb-0">
        <QrReader
          onLoad={setRef}
          constraints={{
            focusMode: "continuous",
            facingMode: "environment",
            advanced: [{ zoom: 2 }],
            fps: 100,
            qrbox: 200,
            aspectRatio: 1,
          }}
          onResult={(result, error) => {
            if (!!result) {
              let res = result?.text;
              props.onSuccess(res.trim());
            }

            if (!!error) {
              //props.onFailed(error);
            }
          }}
        />

        {/* <QrReader
          delay={300}
          facingMode={"environment"}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%" }}
          onLoad={setRef}
          constraints={{
            focusMode: "continuous",
            facingMode: "environment",
            advanced: [{ zoom: 2 }],
            fps: 100,
            qrbox: 200,
            aspectRatio: 1,
          }}
        /> */}

        {/* <QrReader
          resolution={600}
          facingMode="environment"
          onLoad={({ stream }) => (streamRef.current = stream)}
          onScan={handleScan}
          onError={handleError}
        /> */}
      </div>
    </>
  );
};

export default QRScan;
