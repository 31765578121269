import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import CompUserDelegate from "./compUserDelegate";
import helper from "../../utils/helper";
import CompUserExhibitor from "./compUserExhibitor";
import CompUserSponsors from "./compUserSponsors";
import CompUserSpecialInvitees from "./compSpecialInvitess";
import CompUserAwardsOnly from "./compUserAwardsOnly";

const FormStep3 = (props) => {
  const { postData } = props;
  const [users, setUsers] = useState([]);
  const [attendanceMethod, setAttendanceMethod] = useState();

  useEffect(() => {
    initUser();
  }, [props.postData]);

  const initUser = () => {
    if (postData.json_user !== "") {
      let dataUser = postData.json_user;
      let arr = [];
      let i = 0;
      while (i < dataUser.length) {
        arr.push({
          id: dataUser[i].id,
          title: dataUser[i].title,
          fullname: dataUser[i].fullname,
          job_title: dataUser[i].job_title,
          email: dataUser[i].email,
          phone: dataUser[i].phone,
          management_level: dataUser[i].management_level,
          attendance_method: dataUser[i].attendance_method,
          attendance_method_disable: dataUser[i].attendance_method_disable,
          session_type: dataUser[i].session_type,
          award_access: dataUser[i].award_access,
          access_privilege: dataUser[i].access_privilege,
        });
        i++;
      }

      setUsers(arr);
    } else {
      if (postData.included_seats === 0) {
        setUsers([
          {
            id: Date.now() + "" + Math.floor(Math.random() * 10),
            title: "",
            fullname: "",
            job_title: "",
            email: "",
            phone: "",
            management_level: "",
            attendance_method: "",
            attendance_method_disable: false,
            session_type: "",
            award_access: "",
            access_privilege: "",
          },
        ]);
      } else {
        let arr = [];
        let i = 0;
        while (i < postData.included_seats) {
          arr.push({
            id: Date.now() + "" + Math.floor(Math.random() * 1000000) + i,
            title: "",
            fullname: "",
            job_title: "",
            email: "",
            phone: "",
            management_level: "",
            attendance_method: "",
            attendance_method_disable: i === 0 ? false : handleAttendanceDisable(),
            session_type: "",
            award_access: "",
            access_privilege: "",
          });
          i++;
        }

        setUsers(arr);
      }
    }
  };

  const handleAttendanceDisable = () => {
    if(postData?.classification === "Attendee"){
      return true;
    }
    else{
      return false;
    }
  }

  const handleAttendanceMethod = (val) => {

    if (
      props.postData.classification === "Attendee"
    ) {
      let _users = [...users];

      _users = _users.map((item, key) => {
        return {
          ...item,
          attendance_method: key !== 0 ? val.value : item.attendance_method
        }
      })

      setUsers(_users);
      setAttendanceMethod(val);
    } 
  };

  const handleAddUser = () => {

    let _users = [...users];
    let obj = {
      id: Date.now() + "" + Math.floor(Math.random() * 10),
      title: "",
      fullname: "",
      job_title: "",
      email: "",
      phone: "",
      management_level: "",
      attendance_method: attendanceMethod?.value ?? "",
      attendance_method_disable: true,
      session_type: "",
      award_access: "",
      access_privilege: "",
    };

    if (postData?.classification === "Exhibitor") {
     if( (postData?.category === "Charging Booth - Exclusive") || (postData?.category === "Charging Booth - Shared")){
      obj.showOptionAward = true;
     }
    }

    if (postData?.classification === "Awards Only" && postData?.category === "HSE Awards Applicants") {
      obj.award_access = "Yes";
      obj.awardsPrice = postData.awardsPrice;
    }

    _users.push(obj);

    setUsers(_users);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    props.handleSetPostData({
      json_user: handleJsonUser(data),
    });

    props.next();
  };

  const handleJsonUser = (data) => {
    let _user = [...users];

    let i = 0;
    while (i < _user.length) {
      _user[i].title = data[`title_${_user[i]?.id}`]?.value ?? "";
      _user[i].fullname = data[`full_name_${_user[i]?.id}`] ?? "";
      _user[i].job_title = data[`job_title_${_user[i]?.id}`] ?? "";
      _user[i].email = data[`email_${_user[i]?.id}`] ?? "";
      _user[i].phone = data[`contact_number_${_user[i]?.id}`] ?? "";
      _user[i].management_level =
        data[`management_level_dropdown_${_user[i]?.id}`]?.value ?? "";
      _user[i].access_privilege = setAccessPrivilege(
        data[`add_awards_dropdown_${_user[i]?.id}`]?.value
      );

      _user[i].attendance_method = "";
      _user[i].session_type = "";
      _user[i].award_access = "";
      _user[i].awardsPrice = 0;


      switch (postData.classification) {
        case "Attendee":
          _user[i].attendance_method =
            data[`attendance_method_dropdown_${_user[i]?.id}`]?.value ?? "";

          _user[i].session_type =
            data[`select_session_dropdown_${_user[i]?.id}`]?.value ?? "";

          _user[i].award_access =
            data[`add_awards_dropdown_${_user[i]?.id}`]?.value ?? "";

          _user[i].awardsPrice =
            data[`add_awards_dropdown_${_user[i]?.id}`]?.value === "Yes"
              ? postData.awardsPrice
              : 0;
          break;

        case "Sponsor":
          _user[i].session_type =
            data[`select_session_dropdown_${_user[i]?.id}`]?.value ?? "";
          break;

        case "Exhibitor":
          case "Awards Only":
          _user[i].award_access =
          data[`add_awards_dropdown_${_user[i]?.id}`]?.value ?? "";

        _user[i].awardsPrice =
          data[`add_awards_dropdown_${_user[i]?.id}`]?.value === "Yes"
            ? postData.awardsPrice
            : 0;

            break;

        case "Special Invite":
          _user[i].session_type =
            data[`select_session_dropdown_${_user[i]?.id}`]?.value ?? "";

          _user[i].award_access =
            data[`add_awards_dropdown_${_user[i]?.id}`]?.value ?? "";

          _user[i].awardsPrice =
            data[`add_awards_dropdown_${_user[i]?.id}`]?.value === "Yes"
              ? postData.awardsPrice
              : 0;
          break;
          

        default:
          break;
      }

      i++;
    }

    setUsers(_user);
    return _user;
  };

  const setAccessPrivilege = (award) => {
    let arr = [...postData.access_privilege];

    if (award === "Yes") {
      arr.push("Awards");
    }

    return arr.join(",");
  };

  const handleDeleteUser = (id) => {
    let _user = users.filter((item) => item.id !== id);
    setUsers(_user);
  };

  const renderUser = (item, key) => {
    switch (postData?.classification) {
      case "Attendee":
        return (
          <CompUserDelegate
            index={key + 1}
            user={item}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            watch={watch}
            onDelete={(id) => handleDeleteUser(id)}
            seat={postData.included_seats}
            member={helper.checkMember(postData.status)}
            awardsPrice={postData.awardsPrice}
            label={"Attendee"}
            handleAttendanceMethod={(val) => handleAttendanceMethod(val)}
          />
        );

      case "Exhibitor":
        return (
          <CompUserExhibitor
            index={key + 1}
            user={item}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            watch={watch}
            onDelete={(id) => handleDeleteUser(id)}
            seat={postData.included_seats}
            member={helper.checkMember(postData.status)}
            awardsPrice={postData.awardsPrice}
            label={"Representative"}
          />
        );

      case "Sponsor":
        return (
          <CompUserSponsors
            index={key + 1}
            user={item}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            watch={watch}
            onDelete={(id) => handleDeleteUser(id)}
            seat={postData.included_seats}
            member={helper.checkMember(postData.status)}
            awardsPrice={postData.awardsPrice}
            label={"Representative"}
          />
        );

      case "Special Invite":
        return (
          <CompUserSpecialInvitees
            index={key + 1}
            user={item}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            watch={watch}
            onDelete={(id) => handleDeleteUser(id)}
            seat={postData.included_seats}
            member={helper.checkMember(postData.status)}
            awardsPrice={postData.awardsPrice}
            label={"Representative"}
            category={postData.category}
          />
        );

      case "Awards Only":
        return (
          <CompUserAwardsOnly
            index={key + 1}
            user={item}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            watch={watch}
            onDelete={(id) => handleDeleteUser(id)}
            seat={postData.included_seats}
            member={helper.checkMember(postData.status)}
            awardsPrice={postData.awardsPrice}
            label={"Attendee"}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {users.length > 0 && (
        <form
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
          className="w-full"
        >
          <div className="flex flex-col justify-center items-center bg-white p-4 mb-4 rounded-md">
            <img
              src="https://eventqiu.com/assets/hsse_logo.png"
              className="h-24 object-contain"
              alt=""
            />
          </div>

          <div className="font-bold flex flex-col justify-center items-center bg-white p-4 mb-4 rounded-md">
            <div className="w-full md:w-1/2 text-center">
              Note: If you do not have all the registrants’ details at hand, you
              may leave their forms blank and submit. The cost will be included
              to your final package, and you can liaise with the Secretariat to
              update the additional Attendee details
            </div>
          </div>

          <div className="grid md:grid-cols-2 w-full gap-4">
            {users.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`${
                    users.length === 1 ? "col-span-2" : "col-span-1"
                  }`}
                >
                  {renderUser(item, key)}
                </div>
              );
            })}
          </div>

          {(props.postData.included_seats === 0 ||
            props.postData.category === "HSE Awards Applicants") && (
            <div className="mt-5 md:hidden">
              <button
                type="button"
                onClick={() => handleAddUser()}
                className={`py-2 rounded-lg text-white font-bold bg-blue-900  w-full text-center`}
              >
                ADD
              </button>
            </div>
          )}
          <div>
            <div className="flex space-x-3 justify-between mt-5 w-full">
              <button
                type="button"
                onClick={() => props.back()}
                className={`px-4 py-2 rounded-lg text-white font-bold bg-red-500  w-full md:w-max text-center`}
              >
                BACK
              </button>
              {(props.postData.included_seats === 0 ||
                props.postData.category === "HSE Awards Applicants") && (
                <div className="hidden md:block w-full flex">
                  <button
                    type="button"
                    onClick={() => handleAddUser()}
                    className={`py-2 rounded-lg text-white font-bold bg-blue-900  w-full text-center`}
                  >
                    ADD
                  </button>
                </div>
              )}
              <button
                type="submit"
                className={`px-4 py-2 rounded-lg text-white font-bold bg-green-500 w-full md:w-max text-center`}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default FormStep3;
