import CONFIG from "../constanta/config";

function getBackground(url) {
  return url;

  if (url !== undefined && url !== null && url !== "") {
    let arr = url.split("/");
    return CONFIG.URL_MEDIA + "/" + arr[arr.length - 1];
  } else {
    return "";
  }
}

function formatCurrency(amount) {
  if (amount !== null && amount !== "" && amount !== undefined) {
    let number = amount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return "TT$" + number;
  } else {
    return "";
  }
}

function checkMember(status){
  if(status === "Member Company or Ministry"){
    return true;
  }
  else{
    return false;
  }
}

export default {
  getBackground,
  formatCurrency,
  checkMember
};
