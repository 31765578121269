import { useEffect, useState } from 'react';
import QRScan from '../../components/qr_scan';
import { Controller, useForm } from 'react-hook-form';
import CONFIG from '../../constanta/config';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hook/useUser';
import ReactSelect from 'react-select';
import useReport from '../../hook/useReport';
import moment from 'moment';
import { useQuery } from '../../hook/useQuery';

const FormVerification = (props) => {
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState('rfid');
  const [msg, setMsg] = useState('');
  const [count, setCount] = useState(0);
  const [showQR, setShowQR] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors }
  } = useForm();

  const query = useQuery();

  useEffect(() => {
    setShowQR(props.showQrScan);
  }, [props.showQrScan]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      handleCheckinTime();
    }, 1000 * 5);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    localReport.fetchData();
  }, []);

  const handleCheckinTime = async () => {
    const response = await axios.get(
      CONFIG.URL + '/event/report/currentcheckin/' + query.get('id_gate')
    );

    let localTime = Cookies.get('local_time');
    if (localTime && localTime !== undefined && localTime !== 'undefined') {
      if (moment(response?.data?.last_update) > moment(localTime)) {
        localReport.fetchData();
        Cookies.set('local_time', response?.data?.last_update);
      }
    } else {
      Cookies.set('local_time', response?.data?.last_update);
      localReport.fetchData();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  const onSubmit = (data) => {
    if (focus === 'name') {
      checkRFID(data);
    } else if (focus === 'rfid') {
      checkRFID(data);
    } else if (focus === 'qr_code') {
      checkQRCode(data);
    }
    return;
  };

  const checkRFID = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg('');

    const payload = {
      code: data.rfid
    };

    var form_data = new FormData();
    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + '/event/qrcode', form_data, {
          headers: {
            token: Cookies.get('token')
          }
        })
        .then(function (response) {
          setLoading(false);

          if (response?.data.status === 'SUCCESS') {
            props.onSuccess(response?.data?.data[0], 'rfid');
          } else {
            setMsg('Error: ' + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg('Error: Something went wrong please try again');
          setLoading(false);
        });
    } catch (error) {
      setMsg('Error: Something went wrong please try again');
      setLoading(false);
    }
  };

  const checkQRCode = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg('');

    try {
      axios
        .get(CONFIG.URL + '/user/' + data.qr_code, {
          headers: {
            token: Cookies.get('token')
          }
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === 'SUCCESS') {
            props.onSuccess(response?.data?.data[0], 'qr_code', data.qr_code);
          } else {
            setMsg('Error: ' + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg('Error: Something went wrong please try again');
          setLoading(false);
        });
    } catch (error) {
      setMsg('Error: Something went wrong please try again');
      setLoading(false);
    }
  };

  const checkName = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg('');

    try {
      axios
        .get(CONFIG.URL + '/user/' + data.name, {
          headers: {
            token: Cookies.get('token')
          }
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === 'SUCCESS') {
            props.onSuccess(response?.data?.data[0], 'name');
          } else {
            setMsg('Error: ' + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg('Error: Something went wrong please try again');
          setLoading(false);
        });
    } catch (error) {
      setMsg('Error: Something went wrong please try again');
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue('name', '');
    setValue('rfid', '');
    setValue('qr_code', '');
    reset();
  };

  const calcCheckin = () => {
    switch (query.get('id_gate')) {
      case '1': //day 1 person
        return localReport?.data?.checkin_day1_inperson;

      case '2': //day 2 person
        return localReport?.data?.checkin_day2_inperson;

      case '3': //token
        return localReport?.data?.checkin_token;

      case '4': //awards
        return localReport?.data?.checkin_awards;

      case '7': //day 1 meal
        return localReport?.data?.checkin_day1_meal;

      case '8': //day 2 meal
        return localReport?.data?.checkin_day2_meal;

      default:
        return 0;
    }
    // if (localReport?.data) {
    //   const {
    //     checkin_1,
    //     checkout_1,
    //     reentry_1,
    //     checkin_2,
    //     checkout_2,
    //     reentry_2,
    //   } = localReport?.data;

    //   if (query.get("id_gate") === "1") {
    //     return checkin_1 + reentry_1 - checkout_1;
    //   } else if (query.get("id_gate") === "2") {
    //     return checkin_2 + reentry_2 - checkout_2;
    //   } else {
    //     return 0;
    //   }
    // }

    // return 0;

    return 10;
  };

  const handleCloseScan = () => {
    setShowQR(false);
    //props.setShowQrScan(false);
  };

  const handleSuccess = (qr) => {
    handleCloseScan();
    checkRFID({
      rfid: qr
    });
    //checkDataUser(qr);
  };

  const handleFailed = () => {
    setShowQR(false);
    //props.setShowQrScan(false);
  };

  return (
    <>
      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK IN"
          event={`${query.get('gate')} Gate`}
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="relative space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4 z-0">
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="">CHECKIN</div>
          <div className="text-2xl uppercase">{query.get('gate')} Gate</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {calcCheckin() || 0}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10">
          {/* SCAN QR CODE */}
          {/* <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan QR CODE
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
            autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("qr_code")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("qr_code", { required: false })}
            />
          </div> */}

          {/* SEARCH NAME */}
          <div className="relative z-0 flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Search Name</label>
            </div>

            <Controller
              control={control}
              name="rfid"
              rules={{ required: false }}
              render={({ field: { onChange, value, ref, name } }) => (
                <ReactSelect
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  isClearable
                  dropdown
                  onFocus={() => setFocus('name')}
                  className={'react-select'}
                  classNamePrefix={'react-select'}
                  placeholder={'Search Name'}
                  options={props.options}
                  onInputChange={(val) => {
                    if (val.length <= 2) {
                      setOpenMenu(false);
                    } else {
                      setOpenMenu(true);
                    }
                  }}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  menuIsOpen={openMenu}
                  openMenuOnClick={false}
                  openMenuOnFocus={false}
                />
              )}
            />
            <span className="text-xs text-gray-500 mt-1">
              *type at least three characters
            </span>
          </div>

          {/* SCAN QR CODE / RFID */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan QR CODE
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold">
                Clear
              </button>
            </div>

            <input
              autoFocus={props.showQrScan ? false : true}
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus('rfid')}
              className="border-2 px-2 h-[38px] rounded"
              {...register('rfid', { required: false })}
            />
          </div>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        {focus === 'name' && (
          <div className="grid grid-cols-2 gap-5">
            <button
              type="button"
              onClick={() => {
                props.setShowQrScan(true);
                setShowQR(true);
              }}
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}>
              Scan QR
            </button>
            <button
            onClick={() => {
              props.setShowQrScan(false);
            }}
              type="submit"
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
          </div>
        )}

        {(query.get('id_gate') === '1' || query.get('id_gate') === '2') && (
          <div className="flex justify-center items-center w-full">
            <a
              href={`./${'register-event'}?id_gate=${query.get(
                'id_gate'
              )}&gate=${query.get('gate')}`}
              className={`md:min-w-[160px] whitespace-nowrap px-10 py-2 rounded-lg text-white font-bold bg-amber-500  w-full text-center`}>
              ADD USER
            </a>
          </div>
        )}

        {focus === 'rfid' && (
          <div className="grid grid-cols-2 gap-5">
            <button
              type="button"
              onClick={() => {
                props.setShowQrScan(true);
                setShowQR(true);
              }}
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}>
              Scan QR
            </button>
            <button
              type="submit"
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
          </div>
        )}

        {focus === 'qr_code' && (
          <button
            type="submit"
            className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-purple-500  w-full text-center`}>
            {loading ? 'Loading...' : 'Submit Check QR CODE'}
          </button>
        )}

        {/* <div className="w-full flex justify-center items-center">
          <a
            href={`./add-user?id_gate=${query.get("id_gate")}&gate=${query.get(
              "gate"
            )}`}
            className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-amber-500  w-full text-center`}
          >
            Add User
          </a>
        </div> */}
      </form>
    </>
  );
};

export default FormVerification;
