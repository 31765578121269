const listAwards = [
  {
    category: "AMCHAM TT HSE Award Judges",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "AMCHAM TT HSE Award Judges",
    label: "AMCHAM TT HSE Award Judges",
    group: "Individual",
    access_privilege: [
      "Awards",
    ],
  },
  {
    category: "HSE Awards Applicants",
    includedSeats: 2,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "HSE Awards Applicants",
    label: "HSE Awards Applicants",
    group: "Individual",
    access_privilege: [
      "Awards",
    ],
  },
  {
    category: "Individual Non-Conference Attendee",
    includedSeats: 0,
    memberPrice: 750,
    nonMemberPrice: 1000,
    value: "Individual Non-Conference Attendee",
    label: "Individual Non-Conference Attendee",
    group: "Individual",
    access_privilege: [
      "Awards",
    ],
  },
  {
    category: "Table-of-10 Non-Conference Attendee",
    includedSeats: 10,
    memberPrice: 6500,
    nonMemberPrice: 6500,
    value: "Table-of-10 Non-Conference Attendee",
    label: "Table-of-10 Non-Conference Attendee",
    group: "Table (10)",
    access_privilege: [
      "Awards",
    ],
  },
  // {
  //   category: "Add: HSE Awards Applicant",
  //   includedSeats: 0,
  //   memberPrice: 650,
  //   nonMemberPrice: 650,
  //   value: "Add: HSE Awards Applicant",
  //   label: "Add: HSE Awards Applicant",
  //   group: "Individual",
  //   access_privilege: [
  //     "Awards",
  //   ],
  // },
  // {
  //   category: "Add: Individual Non-Conference Attendee",
  //   includedSeats: 0,
  //   memberPrice: 750,
  //   nonMemberPrice: 1000,
  //   value: "Add: Individual Non-Conference Attendee",
  //   label: "Add: Individual Non-Conference Attendee",
  //   group: "Individual",
  //   access_privilege: [
  //     "Awards",
  //   ],
  // },
];

export default listAwards;
