import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import helper from "../../utils/helper";

const titleDropdown = [
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Dr.", value: "Dr." },
  { label: "Professor", value: "Professor" },
];

const awardsDropdown = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const managementLevelsDropdown = [
  { value: "Executive Level", label: "Executive Level" },
  { value: "Senior Level", label: "Senior Level" },
  { value: "Mid Level", label: "Mid Level" },
  { value: "Junior Level", label: "Junior Level" },
  { value: "Not Applicable", label: "Not Applicable" },
];

const sessionDropdown = [
  { value: "Cyber", label: "Cyber" },
  { value: "HSSE", label: "HSSE" },
];

const attendanceMethodDropdown = [
  { value: "In Person", label: "In Person" },
  { value: "Virtual", label: "Virtual" },
];

const CompUserExhibitor = (props) => {
  const {
    register,
    setValue,
    control,
    errors,
    watch,
    index,
    user,
    seat,
    member,
    awardsPrice,
  } = props;

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");

  const [title, setTitle] = useState();
  const [awards, setAwards] = useState();
  const [managementLevels, setManagementLevels] = useState();
  const [session, setSession] = useState();
  const [attendanceMethod, setAttendanceMethod] = useState();

  useEffect(() => {
    if (user.fullname !== "") {
      setValue(`full_name_${user.id}`, user.fullname);
    } else {
      setValue(`full_name_${user.id}`, "");
    }

    if (user.job_title !== "") {
      setValue(`job_title_${user.id}`, user.job_title);
    }

    if (user.email !== "") {
      setValue(`email_${user.id}`, user.email);
      setValue(`confirm_email_${user.id}`, user.email);
    } else {
      setValue(`email_${user.id}`, "");
    }

    if (user.phone !== "") {
      setValue(`contact_number_${user.id}`, user.phone);
    }

    //dropdown
    if (user.title !== "") {
      handleSetTitle();
    }

    if (user.management_level !== "") {
      handleSetManagementLevel();
    }

    if (user.attendance_method !== "") {
      handleSetAttendanceMethod();
    }

    if (user.session_type !== "") {
      handleSetSessionType();
    }

    if (user.award_access !== "") {
      handleSetAwardAccess();
    }

  }, [props.user]);

  const handleSetTitle = () => {
    let val = titleDropdown.find((item) => item.value === user.title);
    setTitle(val);
    setValue(`title_${user.id}`, val);
  };

  const handleSetManagementLevel = () => {
    let val = managementLevelsDropdown.find(
      (item) => item.value === user.management_level
    );
    setManagementLevels(val);
    setValue(`management_level_dropdown_${user.id}`, val);
  };

  const handleSetAttendanceMethod = () => {
    let val = attendanceMethodDropdown.find(
      (item) => item.value === user.attendance_method
    );
    setAttendanceMethod(val);
    setValue(`attendance_method_dropdown_${user.id}`, val);
  };

  const handleSetSessionType = () => {
    let val = sessionDropdown.find((item) => item.value === user.session_type);
    setSession(val);
    setValue(`select_session_dropdown_${user.id}`, val);
  };

  const handleSetAwardAccess = () => {
    let val = awardsDropdown.find((item) => item.value === user.award_access);
    setAwards(val);
    setValue(`add_awards_dropdown_${user.id}`, val);
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const validateEmailMatch = (value) =>
    value === watch(`email_${user.id}`) || "Emails do not match";

  const handleEmailValidation = (email) => {
    if (email === "") {
      return true;
    }

    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  return (
    <div className="space-y-2 px-5 py-5 bg-white rounded-md w-full">
      <div className="flex justify-between items-center w-full">
        <div className="text-left font-bold">
          {props.label} {index} :
        </div>
        {seat === 0 && index !== 1 && (
          <button
            type="button"
            onClick={() => props.onDelete(user.id)}
            className={`w-max px-3 py-1 rounded-lg text-white font-bold bg-red-500 text-sm text-center`}
          >
            DELETE
          </button>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">Full Name:</label>
        <div className="flex flex-col space-y-2 w-full">
          <Controller
            control={control}
            name={`title_${user.id}`}
            rules={{ required: false }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                menuPlacement="auto"
                isClearable
                dropdown
                className={"react-select whitespace-nowrap"}
                classNamePrefix={"react-select"}
                placeholder={"Title Dropdown"}
                options={titleDropdown}
                onChange={(val) => {
                  setTitle(val);
                  setValue(`title_${user.id}`, val);
                }}
                value={title}
              />
            )}
          />

          <input
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 py-2 rounded-sm w-full h-[38px]"
            {...register(`full_name_${user.id}`, {
              required: watch(`email_${user.id}`) !== "" ? true : false,
            })}
          />
        </div>
        {(errors[`title_${user.id}`] || errors[`full_name_${user.id}`]) && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Job Title:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`job_title_${user.id}`, {
            required: false,
          })}
        />
        {errors[`job_title_${user.id}`] && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Email Address:</label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`email_${user.id}`, {
            required: watch(`full_name_${user.id}`) !== "" ? true : false,
            validate: handleEmailValidation,
          })}
        />
        {errors[`email_${user.id}`] && (
          <>
            {errors[`email_${user.id}`]?.type === "validate" ? (
              <span className="text-red-500 text-sm">Email not Valid</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">
          Confirm Email Address:
        </label>
        <input
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`confirm_email_${user.id}`, {
            required: false,
            validate: validateEmailMatch,
          })}
        />
        {errors[`confirm_email_${user.id}`] && (
          <>
            {errors[`confirm_email_${user.id}`]?.type === "validate" ? (
              <span className="text-red-500 text-sm">Emails do not match</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Contact Number:</label>
        <input
          type="text"
          autoComplete="off"
          role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register(`contact_number_${user.id}`, {
            required: false,
          })}
        />
          <label className="w-full  text-gray-500 text-sm">*e.g. (XXX) XXX-XXXX</label>
        {errors[`contact_number_${user.id}`] && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold mb-1">
          Management Level:
        </label>
        <Controller
          control={control}
          name={`management_level_dropdown_${user.id}`}
          rules={{ required: false }}
          render={({ field: { onChange, value, ref, name } }) => (
            <ReactSelect
              menuPlacement="auto"
              isClearable
              dropdown
              className={"react-select"}
              classNamePrefix={"react-select"}
              placeholder={"Management Level Dropdown"}
              options={managementLevelsDropdown}
              onChange={(val) => {
                setManagementLevels(val);
                setValue(`management_level_dropdown_${user.id}`, val);
              }}
              value={managementLevels}
            />
          )}
        />
        {errors[`management_level_dropdown_${user.id}`] && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      {(user.showOptionAward || user.award_access !== "") && (
        <div className="flex flex-col">
          <label className="text-black w-full font-bold mb-1">
            Include Meal:
          </label>
          <Controller
            control={control}
            name={`add_awards_dropdown_${user.id}`}
            rules={{ required: false }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                menuPlacement="auto"
                isClearable
                dropdown
                className={"react-select"}
                classNamePrefix={"react-select"}
                placeholder={"Include Meal"}
                options={awardsDropdown}
                onChange={(val) => {
                  setAwards(val);
                  setValue(`add_awards_dropdown_${user.id}`, val);
                }}
                value={awards}
                getOptionLabel={(option) => {
                  return `${option.label} ${
                    option.label === "Yes"
                      ? ` - ${helper.formatCurrency(awardsPrice)}`
                      : ""
                  }`;
                }}
              />
            )}
          />
          {errors[`add_awards_dropdown_${user.id}`] && (
            <span className="text-red-500 text-sm">This field is required</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CompUserExhibitor;
