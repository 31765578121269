const listExhibitor = [
  {
    category: "Pavillion",
    includedSeats: 6,
    memberPrice: 28875,
    nonMemberPrice: 30375,
    value: "Pavillion",
    label: "Pavillion",
    group: "Pavillion",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 2 In Person",
    ],
  },
  // {
  //   category: "Platinum",
  //   includedSeats: 2,
  //   memberPrice: 10875,
  //   nonMemberPrice: 12375,
  //   value: "Platinum",
  //   label: "Platinum",
  //   group: "Platinum",
  //   access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person"],
  // },
  {
    category: "Gold",
    includedSeats: 2,
    memberPrice: 9750,
    nonMemberPrice: 11250,
    value: "Gold",
    label: "Gold",
    group: "Gold",
    access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person"],
  },
  {
    category: "Silver",
    includedSeats: 1,
    memberPrice: 5500,
    nonMemberPrice: 6500,
    value: "Silver",
    label: "Silver",
    group: "Silver",
    access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person"],
  },
  {
    category: "Charging Booth - Exclusive",
    includedSeats: 0,
    memberPrice: 6500,
    nonMemberPrice: 7000,
    value: "Charging Booth - Exclusive",
    label: "Charging Booth - Exclusive",
    group: "Charging Booth - Exclusive",
    access_privilege: [],
  },
  {
    category: "Charging Booth - Shared",
    includedSeats: 0,
    memberPrice: 5000,
    nonMemberPrice: 5500,
    value: "Charging Booth - Shared",
    label: "Charging Booth - Shared",
    group: "Charging Booth - Shared",
    access_privilege: [],
  },
  {
    category: "Add: Representative (with Meal)",
    includedSeats: 0,
    memberPrice: 1500,
    nonMemberPrice: 1500,
    value: "Add: Representative (with Meal)",
    label: "Add: Representative (with Meal)",
    group: "Add: Representative (with Meal)",
    access_privilege: ["Day 1 In Person", "Day 2 In Person"],
  },
  {
    category: "Add: Representative (with No Meal)",
    includedSeats: 0,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Add: Representative (with No Meal)",
    label: "Add: Representative (with No Meal)",
    group: "Add: Representative (with No Meal)",
    access_privilege: [],
  },
];

export default listExhibitor;
