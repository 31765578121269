import { useEffect } from 'react';
import { useQuery } from '../../hook/useQuery';

const FormSuccess = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `./${'checkin'}?id_gate=${query.get(
        'id_gate'
      )}&gate=${query.get('gate')}`;
    }, 500);
  }, []);

  const query = useQuery();

  return (
    <div className="space-y-2 px-5 py-5 bg-white rounded-md w-full">
      <div>
        <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
          CHECKIN SUCCESS
        </div>
        {/* <div className="text-center font-bold  text-2xl mb-5">
          Congratulations!
        </div>
        <div className="text-center font-bold mb-1">
          You have successfully completed your registration(s) for AMCHAM’s HSSE
          Conference 2024.
          <br />
          We look forward to hosting you.
          <br />
          AMCHAM HSSE Conference Team
        </div>

        <div className="flex flex-col justify-center items-center bg-white p-4 mb-4 rounded-md mt-5">
          <img
            src="https://eventqiu.com/assets/logo-in.jpg"
            className="h-48 object-contain"
            alt=""
          />
        </div> */}

        <div className="w-full flex justify-center items-center">
          {/* <button
            type="button"
            onClick={() => {
              window.location.reload();
            }}
            className={`text-white py-2 rounded-lg   font-bold bg-green-500  w-full md:max-w-[160px] text-center uppercase`}
          >
            OK
          </button> */}

          <a
            href={`./${'checkin'}?id_gate=${query.get(
              'id_gate'
            )}&gate=${query.get('gate')}`}
            className={`md:min-w-[160px] whitespace-nowrap px-10 py-2 rounded-lg text-white font-bold bg-green-500  text-center`}>
            OK
          </a>
        </div>
      </div>
    </div>
  );
};

export default FormSuccess;
