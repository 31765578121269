import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormStep1 from './formStep1';
import FormStep2 from './formStep2';
import FormStep3 from './formStep3';
import axios from 'axios';
import CONFIG from '../../constanta/config';
import FormSummary from './formSummary';
import FormSuccess from './formSuccess';
import listDelegates from './listDelegates';
import listExhibitor from './listExhibitor';
import listSponsors from './listSponsors';
import listSpecialInvitees from './listSpecialInvitees';
import listAwards from './listAwards';

const PageRegistration = (props) => {
  const [step, setStep] = useState('step1');
  const navigate = useNavigate();
  const [memberCompaniesDropdown, setMemberCompaniesDropdown] = useState([]);

  // const [postData, setPostData] = useState(obj);

  const [postData, setPostData] = useState({
    classification: '',
    status: '',
    booth_type: '',
    company_name: '',
    company_address: '',
    company_phone: '',
    company_sector: '',
    liaison_title: '',
    liaison_name: '',
    liaison_designation: '',
    liaison_email: '',
    liaison_phone: '',
    grouping_type: '',
    category: '',
    admission_fee: '',
    json_user: '',
    access_privilege: '',
    pin: '',
    included_seats: 0,
    categoryPrice: 0,
    awardsPrice: 0
  });

  useEffect(() => {
    getListCompanies();
  }, []);

  // useEffect(() => {
  //   console.log(postData);
  //   console.log(JSON.stringify(postData));
  // }, [postData]);

  const handleSetPostData = (data) => {
    setPostData({
      ...postData,
      ...data
    });
  };

  const getListCompanies = async () => {
    const response = await axios.get(CONFIG.URL + '/user/company');

    if (response?.data?.status === 'SUCCESS') {
      let arr = response?.data?.data?.map((item) => {
        return {
          id: item.id,
          value: item.company_name,
          label: item.company_name,
          name: item.company_name,
          address: item.company_address,
          telephone: item.company_phone
        };
      });

      setMemberCompaniesDropdown(arr);
    }
  };

  const getListCategory = (classification) => {
    // const classificationDropdown = [
    //   { value: "Attendee", label: "Attendee" },
    //   { value: "Exhibitor", label: "Exhibitor" },
    //   { value: "Sponsor", label: "Sponsor" },
    //   { value: "Special Invite", label: "Special Invite" },
    //   { value: "Awards Only", label: "Awards Only" },
    // ];

    switch (classification) {
      case 'Attendee':
        return {
          data: listDelegates,
          label: 'Select Attendee(s) Category:',
          placeholder: 'Category Dropdown'
        };

      case 'Exhibitor':
        return {
          data: listExhibitor,
          label: 'Select Booth Category:',
          placeholder: 'Category Dropdown'
        };

      case 'Sponsor':
        return {
          data: listSponsors,
          label: 'Select Sponsor Category:',
          placeholder: 'Category Dropdown'
        };

      case 'Special Invite':
        return {
          data: listSpecialInvitees,
          label: 'Select Special Invitee Category:',
          placeholder: 'Category Dropdown'
        };

      case 'Awards Only':
        return {
          data: listAwards,
          label: 'Select Awards Attendee Category:',
          placeholder: 'Category Dropdown'
        };

      default:
        return [];
    }
  };

  if (props?.setting?.event_info === 'disable') {
    return (
      <div
        className="w-full min-h-screen flex flex-col justify-center items-center bg-gray-200 px-5 md:px-20 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url('${props?.setting?.background}')`
        }}>
        <div className="flex flex-col justify-center center-center my-10 w-full md:w-1/2">
          <div className="space-y-2 px-5 py-5 bg-white rounded-md ">
            <div className='flex flex-col justify-center items-center '>
              <img
                src="https://eventqiu.com/assets/hsse_logo.png"
                className="h-24 object-contain mb-4"
                alt=""
              />
              <div className="text-center font-bold  text-2xl mb-5 bg-red-500 text-white p-10 rounded-md">
                Registration for the AMCHAM HSSE Conference & Exhibition 2024 Is
                Now Closed
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 md:px-20 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url('${props?.setting?.background}')`
        }}>
        <div className="flex flex-col justify-start items-start my-10 w-full">
          {/* <div className="text-white mb-10">{JSON.stringify(postData)}</div> */}

          {/* FORM STEP1 */}
          {step === 'step1' && (
            <FormStep1
              handleSetPostData={(data) => handleSetPostData(data)}
              next={(step) => setStep(step)}
              postData={postData}
              memberCompaniesDropdown={memberCompaniesDropdown}
            />
          )}

          {/* FORM STEP2 */}
          {step === 'step2' && (
            <FormStep2
              handleSetPostData={(data) => handleSetPostData(data)}
              next={() => setStep('step3_delegates')}
              back={() => setStep('step1')}
              postData={postData}
              listCategory={getListCategory(postData.classification)}
            />
          )}

          {/* FORM STEP3 DELEGATES */}
          {step === 'step3_delegates' && (
            <FormStep3
              handleSetPostData={(data) => handleSetPostData(data)}
              next={() => setStep('summary')}
              back={() => setStep('step2')}
              postData={postData}
            />
          )}

          {/* FORM SUMMARY */}
          {step === 'summary' && (
            <FormSummary
              next={() => setStep('success')}
              back={(val) => setStep(val)}
              postData={postData}
            />
          )}

          {/* FORM SUCCESS */}
          {step === 'success' && <FormSuccess postData={postData} />}
        </div>
      </div>
    </>
  );
};

export default PageRegistration;
