import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormStep1 from "./formStep1";
import FormStep2 from "./formStep2";
import FormStep3 from "./formStep3";
import axios from "axios";
import CONFIG from "../../constanta/config";
import FormSummary from "./formSummary";
import FormSuccess from "./formSuccess";
import listDelegates from "./listDelegates";
import listExhibitor from "./listExhibitor";
import listSponsors from "./listSponsors";
import listSpecialInvitees from "./listSpecialInvitees";
import listAwards from "./listAwards";



const PageRegistrationEvent = (props) => {
  const [step, setStep] = useState("step1");
  const navigate = useNavigate();
  const [memberCompaniesDropdown, setMemberCompaniesDropdown] = useState([]);

  // const [postData, setPostData] = useState(obj);


  useEffect(() => {
    //getListCompanies();
  }, []);

  const getListCompanies = async () => {
    const response = await axios.get(CONFIG.URL + "/user/company");

    if (response?.data?.status === "SUCCESS") {
      let arr = response?.data?.data?.map((item) => {
        return {
          id: item.id,
          value: item.company_name,
          label: item.company_name,
          name: item.company_name,
          address: item.company_address,
          telephone: item.company_phone,
        };
      });

      setMemberCompaniesDropdown(arr);
    }
  };

  return (
    <>
      <div
        className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 md:px-20 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url('${props?.setting?.background}')`,
        }}
      >
        <div className="flex flex-col justify-start items-start my-10 w-full">
          {/* <div className="text-white mb-10">{JSON.stringify(postData)}</div> */}

          {/* FORM STEP1 */}
          {step === "step1" && (
            <FormStep1
              next={(step) => setStep(step)}
              memberCompaniesDropdown={memberCompaniesDropdown}
            />
          )}

          {/* FORM SUCCESS */}
          {step === "success" && <FormSuccess postData={{}} />}
        </div>
      </div>
    </>
  );
};

export default PageRegistrationEvent;
