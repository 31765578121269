const listSponsors = [
  {
    category: "Title Sponsor",
    includedSeats: 20,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Title Sponsor",
    label: "Title Sponsor",
    group: "Individual",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 1 Virtual)",
      "Day 2 In Person",
      "Day 2 Virtual",
    ],
  },
  {
    category: "Platinum Sponsor",
    includedSeats: 3,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Platinum Sponsor",
    label: "Platinum Sponsor",
    group: "Individual",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 2 In Person",
    ],
  },
  {
    category: "Gold Sponsor",
    includedSeats: 2,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Gold Sponsor",
    label: "Gold Sponsor",
    group: "Individual",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 2 Virtual",
    ],
  },
  {
    category: "Silver Sponsor",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Silver Sponsor",
    label: "Silver Sponsor",
    group: "Individual",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 2 Virtual",
    ],
  },
  {
    category: "Session Sponsor",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Session Sponsor",
    label: "Session Sponsor",
    group: "Individual",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 2 Virtual",
    ],
  },
  {
    category: "Breakfast Sponsor",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Breakfast Sponsor",
    label: "Breakfast Sponsor",
    group: "Individual",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 2 Virtual",
    ],
  },
  {
    category: "Cocktail Sponsor",
    includedSeats: 1,
    memberPrice: 0,
    nonMemberPrice: 0,
    value: "Cocktail Sponsor",
    label: "Cocktail Sponsor",
    group: "Individual",
    access_privilege: [
      "Token",
      "Day 1 In Person",
      "Day 2 Virtual",
    ],
  },
];

export default listSponsors;
