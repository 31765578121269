const listDelegates = [
  {
    category: "Table-of-10 - Full Conference Pass",
    includedSeats: 10,
    memberPrice: 40500,
    nonMemberPrice: 49500,
    value: "Table-of-10 - Full Conference Pass",
    label: "Table-of-10 - Full Conference Pass",
    group: "Table (10)",
    access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person"],
  },
  {
    category: "Table-of-10 - Day 1 Pass (In Person)",
    includedSeats: 10,
    memberPrice: 27000,
    nonMemberPrice: 31500,
    value: "Table-of-10 - Day 1 Pass (In Person)",
    label: "Table-of-10 - Day 1 Pass (In Person)",
    group: "Table (10)",
    access_privilege: ["Token", "Day 1 In Person"],
  },
  {
    category: "Table-of-10 - Day 2 Pass (In Person)",
    includedSeats: 10,
    memberPrice: 27000,
    nonMemberPrice: 31500,
    value: "Table-of-10 - Day 2 Pass (In Person)",
    label: "Table-of-10 - Day 2 Pass (In Person)",
    group: "Table (10)",
    access_privilege: ["Token", "Day 2 In Person"],
  },
  {
    category: "Individual - Full Conference Pass (In Person)",
    includedSeats: 0,
    memberPrice: 4500,
    nonMemberPrice: 5500,
    value: "Individual - Full Conference Pass (In Person)",
    label: "Individual - Full Conference Pass (In Person)",
    group: "Individual",
    access_privilege: ["Token", "Day 1 In Person", "Day 2 In Person"],
  },
  {
    category: "Individual - Full Conference Pass (Virtual)",
    includedSeats: 0,
    memberPrice: 2500,
    nonMemberPrice: 3000,
    value: "Individual - Full Conference Pass (Virtual)",
    label: "Individual - Full Conference Pass (Virtual)",
    group: "Individual",
    access_privilege: ["Token", "Day 1 Virtual", "Day 2 Virtual"],
  },
  {
    category: "Individual - Day 1 Pass (In Person)",
    includedSeats: 0,
    memberPrice: 3000,
    nonMemberPrice: 3500,
    value: "Individual - Day 1 Pass (In Person)",
    label: "Individual - Day 1 Pass (In Person)",
    group: "Individual",
    access_privilege: ["Token", "Day 1 In Person"],
  },
  {
    category: "Individual - Day 1 Pass (Virtual)",
    includedSeats: 0,
    memberPrice: 1400,
    nonMemberPrice: 1500,
    value: "Individual - Day 1 Pass (Virtual)",
    label: "Individual - Day 1 Pass (Virtual)",
    group: "Individual",
    access_privilege: ["Token", "Day 1 Virtual"],
  },
  {
    category: "Individual - Day 2 Pass (In Person)",
    includedSeats: 0,
    memberPrice: 3000,
    nonMemberPrice: 3500,
    value: "Individual - Day 2 Pass (In Person)",
    label: "Individual - Day 2 Pass (In Person)",
    group: "Individual",
    access_privilege: ["Token", "Day 2 In Person"],
  },
  {
    category: "Individual - Day 2 Pass (Virtual)",
    includedSeats: 0,
    memberPrice: 1400,
    nonMemberPrice: 1500,
    value: "Individual - Day 2 Pass (Virtual)",
    label: "Individual - Day 2 Pass (Virtual)",
    group: "Individual",
    access_privilege: ["Token", "Day 2 Virtual"],
  },
  // {
  //   category: "Add: Awards Access",
  //   includedSeats: 0,
  //   memberPrice: 650,
  //   nonMemberPrice: 750,
  //   value: "Add: Awards Access",
  //   label: "Add: Awards Access",
  //   access_privilege: ["Awards"]
  // },
];

export default listDelegates;
