import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import helper from "../../utils/helper";
import axios from "axios";
import CONFIG from "../../constanta/config";

const FormSummary = (props) => {
  const { postData, next } = props;
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");
  const [attendUser, setAttendUser] = useState([]);
  const [attendUserPost, setAttendUserPost] = useState([]);
  

  const liason = {
    title: "",
    fullname: postData.liaison_name,
    job_title: postData.liaison_title,
    email: postData.liaison_email,
    phone: "",
    management_level: "",
    attendance_method: "",
    session_type: "",
    award_access: "",
    access_privilege: postData.access_privilege.join(","),
    awardsPrice: 0,
  };

  useEffect(() => {
    let arr = [...postData.json_user];
    setAttendUserPost(arr);
    arr = arr.filter((item) => item.fullname !== "");
    if (arr.length === 0) {
      setAttendUser([liason]);
    } else {
      setAttendUser(arr);
    }

    
  }, [props.postData]);

  function calculateTotalAwardsPrice(attendees) {
    return attendees.reduce(
      (total, attendee) => (total += attendee.awardsPrice),
      0
    );
  }

  const calcTotalPrice = () => {
    const totalAwardsPrice = parseInt(calculateTotalAwardsPrice(attendUser));
    let totalCategoryPrice = 0;

    if (postData.included_seats !== 0) {
      totalCategoryPrice = postData.categoryPrice;
    } else {
      totalCategoryPrice =
        attendUser.length === 0
          ? postData.categoryPrice
          : postData.categoryPrice * attendUser.length;
    }
    return totalCategoryPrice + totalAwardsPrice;
  };

  const calcTotalPriceVAT = () => {
    const totalAwardsPrice = parseInt(calculateTotalAwardsPrice(attendUser));
    let totalCategoryPrice = 0;

    if (postData.included_seats !== 0) {
      totalCategoryPrice = postData.categoryPrice;
    } else {
      totalCategoryPrice =
        attendUser.length === 0
          ? postData.categoryPrice
          : postData.categoryPrice * attendUser.length;
    }
    return ((totalCategoryPrice + totalAwardsPrice) * (12.5/100)).toFixed(2);
  };

  const handleSubmitForm = () => {
    setError("");
    setLoading(true);

    const payload = { ...postData };

    let _attendUserPost = [...attendUserPost];
    _attendUserPost = _attendUserPost.map((item) => {
      return {
        ...item,
        fullname: item.fullname === "" ? "not available" : item.fullname
      }
    })

  
    const json = JSON.stringify({
      user: _attendUserPost,
    });
    payload.json_user = json;
    payload.admission_fee = calcTotalPrice();

    // console.log(JSON.stringify(payload));
    // setLoading(false);
    // return;
  
    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/user/register", form_data)
        .then(function (response) {
          setLoading(false);
          if (response?.data?.status === "SUCCESS") {
            next();
          } else {
            setError(response?.data?.message);
          }
        })
        .catch(function (error) {
          setLoading(false);
          setError("Something wrong, please try again.");
        });
    } catch (error) {
      setLoading(false);
      setError("Something wrong, please try again.");
    }
  };

  const renderStringAward = () => {
    
    switch (postData.classification) {
      case "Exhibitor":
        return "Additional Representative (with Meal)";

      case "Awards Only":
        return "HSE Awards Applicants";

      default:
        return "Awards Function";
    }
  };

  return (
    <div className="space-y-2 px-5 py-5 bg-white rounded-md w-full">
      <div>
        <div className="text-center font-bold mb-1">Order Summary</div>
        <div className="text-center font-bold mb-1 uppercase">
          {postData.classification}
        </div>
      </div>

      <div className="w-full">
        <div className="overflow-x-auto w-full">
          <table className="min-w-full table-fixed border border-white divide-y divide-gray-200">
            <thead className="bg-orange-500">
              <tr key={10000}>
                <th className="whitespace-nowrap w-1/6 px-3 py-2 text-left text-xs font-bold text-white uppercase tracking-wider border border-white">
                  Attendee Name
                </th>
                <th className="whitespace-nowrap w-1/6 px-3 py-2 text-left text-xs font-bold text-white uppercase tracking-wider border border-white">
                  Job Title
                </th>
                <th className="w-1/6 px-3 py-2 text-left text-xs font-bold text-white uppercase tracking-wider border border-white">
                  Company
                </th>
                <th className="w-1/6 px-3 py-2 text-left text-xs font-bold text-white uppercase tracking-wider border border-white">
                  Membership Status
                </th>
                <th className="w-1/6 px-3 py-2 text-left text-xs font-bold text-white uppercase tracking-wider border border-white">
                  Classification
                </th>
                <th className="w-1/3 px-3 py-2 text-left text-xs font-bold text-white uppercase tracking-wider border border-white">
                  Items
                </th>
                <th className="whitespace-nowrap w-1/6 px-3 py-2 text-center text-xs font-bold text-white uppercase tracking-wider border border-white">
                  Total (TTD)
                </th>
                <th className=" whitespace-nowrap w-1/6 px-3 py-2 text-center text-xs font-bold text-white uppercase tracking-wider border border-white">
                  VAT
                </th>
              </tr>
            </thead>
            <tbody className={`bg-orange-100 divide-y divide-gray-200`}>
              {attendUser.length === 0 && (
                <tr>
                  <td
                    colSpan={7}
                    className="px-3 py-2 text-center font-bold border border-white"
                  >
                    No Representative Information
                  </td>
                </tr>
              )}

              {attendUser?.map((item, key) => {
                return (
                  <tr
                    key={key}
                    className={`${
                      key % 2 == 0 ? "bg-orange-200" : "bg-orange-100"
                    }`}
                  >
                    <td className="px-3 py-2 whitespace-nowrap border border-white">
                      {item.title} {item.fullname}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap border border-white">
                      {item.job_title}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap border border-white">
                      {postData.company_name}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap border border-white">
                      {postData.status}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap border border-white">
                      {postData.classification}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap border border-white">
                      <>
                        {postData.included_seats !== 0 ? (
                          <>
                            <div>{postData.category} - {helper.formatCurrency(
                                postData.categoryPrice
                              )}</div>
                            <>
                            {item.awardsPrice !== 0  ? (
                              `Add: ${renderStringAward()} - ${helper.formatCurrency(
                                postData.awardsPrice
                              )}`
                            ) : (
                              <>
                                {postData.classification ===
                                "Special Invite" && item.award_access === "Yes" ? (
                                  <>{`Add: ${renderStringAward()} - ${helper.formatCurrency(
                                    postData.awardsPrice
                                  )}`}</>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                            </>
                          </>
                        ) : (
                          <div className="flex flex-col">
                            <div>
                              {postData.category} -{" "}
                              {helper.formatCurrency(postData.categoryPrice)}
                            </div>
                            {item.awardsPrice !== 0 ? (
                              <div>
                                Add: {renderStringAward()} - $
                                {helper.formatCurrency(postData.awardsPrice)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </>
                    </td>
                    <td
                      valign="top"
                      className="px-3 py-2 whitespace-nowrap border border-white font-bold text-right"
                    >
                      <>
                        {postData.included_seats !== 0 ? (
                          <>
                            {item.awardsPrice !== 0
                              ? `${helper.formatCurrency(postData.awardsPrice)}`
                              : key === 0 && helper.formatCurrency(
                                postData.categoryPrice
                              )}
                          </>
                        ) : (
                          <>
                            {item.awardsPrice !== 0
                              ? helper.formatCurrency(
                                  postData.categoryPrice + postData.awardsPrice
                                )
                              : helper.formatCurrency(postData.categoryPrice)}
                          </>
                        )}
                      </>
                    </td>
                    <td
                      valign="top"
                      className="px-3 py-2 whitespace-nowrap border border-white font-bold text-right"
                    >
                      <>
                        {postData.included_seats !== 0 ? (
                          <>
                            {item.awardsPrice !== 0
                              ? `${helper.formatCurrency((postData.awardsPrice * (12.5/100)).toFixed(2))}`
                              : key === 0 && helper.formatCurrency((postData.categoryPrice * (12.5/100)).toFixed(2))}
                          </>
                        ) : (
                          <>
                            {item.awardsPrice !== 0
                              ? helper.formatCurrency(
                                  ((postData.categoryPrice + postData.awardsPrice) * (12.5/100)).toFixed(2)
                                )
                              : helper.formatCurrency((postData.categoryPrice * (12.5/100)).toFixed(2))}
                          </>
                        )}
                      </>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="bg-orange-300 font-bold">
              {/* {postData.included_seats !== 0 && (
                <tr>
                  <td
                    colSpan={6}
                    className="px-3 py-2 text-right font-bold border border-white"
                  >
                    {postData.category}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap border border-white text-right">
                    {helper.formatCurrency(postData.categoryPrice)}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap border border-white text-right">
                    {helper.formatCurrency((postData.categoryPrice * (12.5/100)).toFixed(2))}
                  </td>
                  
                </tr>
              )} */}

              <tr>
                <td
                  colSpan={6}
                  className="px-3 py-2 text-right font-bold border border-white"
                >
                  TOTAL DUE:
                </td>
                <td className="px-3 py-2 whitespace-nowrap border border-white text-right">
                  {helper.formatCurrency(calcTotalPrice())}
                </td>
                <td className="px-3 py-2 whitespace-nowrap border border-white text-right">
                  {helper.formatCurrency(calcTotalPriceVAT())}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div className="font-bold">
        <div className="mt-4">
          Note: All registrations completed and paid between July 19th –
          September 15th are eligible for a 10% early bird discount
        </div>
      </div>

      <div>
        {error !== "" && (
          <span className="text-red-500 text-sm w-full flex justify-center items-center font-bold mt-5">
            Error: {error}
          </span>
        )}
        <div className="flex justify-between mt-10 w-full">
          <button
            type="button"
            onClick={loading ? () => {} : () => props.back("step3_delegates")}
            className={`text-white py-2 rounded-lg  font-bold bg-red-500  w-full md:max-w-[160px] text-center`}
          >
            BACK
          </button>
          <button
            type="button"
            onClick={loading ? () => {} : () => handleSubmitForm()}
            className={`text-white py-2 rounded-lg  font-bold bg-green-500  w-full md:max-w-[160px] text-center uppercase`}
          >
            {loading ? "Loading..." : "SUBMIT ORDER"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormSummary;
