import { useEffect, useState } from 'react';
import CONFIG from '../../constanta/config';
import axios from 'axios';
import Cookies from 'js-cookie';
import FormVerification from './verification.from';
import FormThankyou from './thankyou.from';
import FormNameResult from './name-result';
import FormRFIDResult from './rfid-result';
import FormThankyouRFID from './thankyou-rfid.from';
import helper from '../../utils/helper';
import FormQRResult from './qr-result';
import { useQuery } from '../../hook/useQuery';

const PageLeadCapture = (props) => {
  const query = useQuery();
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState('verification');
  const [qrCode, setQrCode] = useState('');
  const [leadData, setLeadData] = useState(null);

  useEffect(() => {
    if (!query.get('code')) {
      window.location.href = './home';
    } else {
      getLead();
    }
  }, []);

  useEffect(() => {
    // let a = [{ label: "dicky", value: "0943898324", id_user: "1" }];
    // setOptions(a);
    //getLitsUser();
  }, []);

  const getLead = async () => {
    const response = await axios.get(
      CONFIG.URL + '/event/lead/' + query.get('code'),
      {
        headers: {
          token: CONFIG.TOKEN_LEAD
        }
      }
    );

    if (response?.data?.status === 'SUCCESS') {
      // let list = response.data?.data?.filter((item) => item.is_register_at_event !== "1");

      if (response?.data?.data?.length > 0) {
        setLeadData(response?.data?.data[0]);
      } else {
        window.location.href = './home';
      }
    } else {
      window.location.href = './home';
    }
  };

  const handleSuccesverification = (data, type, qr = '') => {
    setUser(data);

    if (type === 'name') {
      setState('name-result');
    } else if (type === 'rfid') {
      setState('rfid-result');
    } else {
      setState('qr-result');
      setQrCode(qr);
    }
  };

  const handleResetForm = () => {
    window.location.reload();
  };

  const handleSuccessCheckinName = () => {
    setState('thankyou');
  };

  const handleSuccessCheckinRFID = () => {
    setState('thankyou-rfid');
  };

  return (
    <>
      {
        <div
          className=" w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url('${helper.getBackground(
              props?.setting?.background
            )}')`
          }}>
          <div className="flex flex-col justify-center items-center py-10 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === 'verification' && (
                <>
                  <FormVerification
                    onSuccess={(data, type, qrCode = '') =>
                      handleSuccesverification(data, type, qrCode)
                    }
                    leadcapture={leadData}
                    options={options}
                  />
                </>
              )}

              {/* NAME RESULT */}
              {state === 'name-result' && (
                <FormNameResult
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinName()}
                />
              )}

              {/* NAME RESULT */}
              {state === 'rfid-result' && (
                <FormRFIDResult
                  leadcapture={leadData}
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinRFID()}
                />
              )}

              {/* NAME RESULT */}
              {state === 'qr-result' && (
                <FormQRResult
                  leadcapture={leadData}
                  qrCode={qrCode}
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinName()}
                />
              )}

              {/* THANK YOU NAME RESULT */}
              {state === 'thankyou' && (
                <FormThankyou user={user} ok={() => handleResetForm()} />
              )}

              {/* THANK YOU RFID RESULT */}
              {state === 'thankyou-rfid' && (
                <FormThankyouRFID ok={() => handleResetForm()} />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageLeadCapture;
